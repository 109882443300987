import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dialog, TooltipTrigger } from 'react-aria-components';
import O5H4Image from '@public/images/bot_front.webp';
import EpisodeAITitleField from './EpisodeAITitleField';
import EpisodeAITagsField from './EpisodeAITagsField';
import EpisodeAINewsletterField from './EpisodeAINewsletterField';
import EpisodeAIChaptersField from './EpisodeAIChaptersField';
import EpisodeAIDescriptionField from '@/components/EpisodeAI/EpisodeAIPanel/EpisodeAIDescriptionField';
import { useEpisodeBeingEdited } from '@/components/app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { EPISODE_STATUS } from '@/utils/constants';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import Alert from '@/components/ui/atoms/Alert';
import RouterLink from '@/components/Link/RouterLink';
import useAICustomPromptsQuery from '@/queries/ai/useAICustomPromptsQuery.hook';
import { useParams } from 'react-router';

interface EpisodeAIPanelProps {
    generateButtonDataTestId: string;
}

const EpisodeAIPanel = ({ generateButtonDataTestId }: EpisodeAIPanelProps) => {
    const { episodeId, showId } = useParams<{ showId: string; episodeId: string }>();
    const episode = useEpisodeBeingEdited();
    const customPromptSettings = useAICustomPromptsQuery({
        showId,
    });
    const isNewsletterEnabled = episode?.state !== EPISODE_STATUS.ONLINE;
    const hasToneOfVoice = customPromptSettings.data?.find(
        (s) => s.field === 'tone_of_voice',
    )?.prompt;
    const hasDismissedToneOfVoiceAlert =
        localStorage.getItem(`episode-${episodeId}-ai-tone-of-voice-alert-dismissed`) === 'true';
    const [isToneOfVoiceAlertVisible, setIsToneOfVoiceAlertVisible] = useState(
        !hasDismissedToneOfVoiceAlert,
    );

    const handleCloseToneOfVoiceAlert = () => {
        localStorage.setItem(`episode-${episodeId}-ai-tone-of-voice-alert-dismissed`, 'true');
        setIsToneOfVoiceAlertVisible(false);
    };

    return (
        <>
            {isToneOfVoiceAlertVisible && !hasToneOfVoice && !customPromptSettings.isLoading && (
                <Alert
                    variant="info"
                    defaultIcon
                    action={
                        <RouterLink
                            to={`/app/show/${showId}/settings/ai`}
                            variant="button-primary"
                            target="_blank"
                        >
                            <FormattedMessage defaultMessage="Configurer" />
                        </RouterLink>
                    }
                    closable={true}
                    onClose={handleCloseToneOfVoiceAlert}
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Ajustez la tonalité de AI pour l'adapter à vos besoins !" />
                    </Text>
                </Alert>
            )}
            <Wrapper>
                <Header>
                    <Sparkles />
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        Ausha Intelligence
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Ausha Intelligence utilise votre transcription pour générer automatiquement votre titre, description, tags, chapitres et newsletter. Toute modification apportée à votre transcription sera mise à jour jusqu'à trois fois." />
                        </Tooltip>
                    </TooltipTrigger>
                </Header>
                <Content>
                    <BotIllustration src={O5H4Image} />
                    <ContentContainer>
                        <EpisodeAITitleField
                            generateButtonDataTestId={`${generateButtonDataTestId}-title`}
                        />
                        <EpisodeAIDescriptionField
                            generateButtonDataTestId={`${generateButtonDataTestId}-description`}
                        />
                        <EpisodeAITagsField
                            generateButtonDataTestId={`${generateButtonDataTestId}-tags`}
                        />
                        <EpisodeAIChaptersField
                            generateButtonDataTestId={`${generateButtonDataTestId}-chapters`}
                        />
                        {isNewsletterEnabled && (
                            <EpisodeAINewsletterField
                                generateButtonDataTestId={`${generateButtonDataTestId}-newsletter`}
                            />
                        )}
                    </ContentContainer>
                </Content>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Dialog)`
    display: flex;
    flex-direction: column;
    padding-block: 1.5rem;
    padding-inline: 1rem;
    background: var(--gradient-aurora);
    border-radius: var(--r-l);
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    position: relative;
    margin-top: 1rem;
`;

const Sparkles = styled(SparklesIcon)`
    width: 1rem;
    height: 1rem;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

const ContentContainer = styled.div`
    position: relative;
    height: 100%;
    padding: 1rem;
    background: var(--white500);
    border-radius: var(--r-l);
`;

const BotIllustration = styled.img`
    position: absolute;
    top: -4.5rem;
    right: 1rem;
    width: 109px;
    height: 182px;
    clip-path: inset(0 0 110px 0);
`;

export default EpisodeAIPanel;
