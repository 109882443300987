import { useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import FetcherShowTemplate from '../fetchers/FetcherShowTemplate';
import CTAAddNewButtonModal from '../molecules/CTAEditButtonModal';
import CTADeleteButtonModal from '../molecules/CTADeleteButtonModal';
import FetcherPageSettings from '../fetchers/FetcherPageSettings';
import { useSwitch } from '@hooks/useSwitch';
import { connect } from '../decorators/connect';
import CTAControlPanel from './CTAControlPanel';
import FetcherCallToActionButtons from '../fetchers/FetcherCallToActionButtons';
import CTACreateButtonModal from '../molecules/CTACreateButtonModal';
import CTADeleteLocationModal from '../molecules/CTADeleteLocationModal';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/utils/cssVariables';
import styled from 'styled-components';

export const LINKS_NAMES = {
    PAGE_PODCAST: 'podcast_page',
    CROWDFUNDING: 'crowdfunding',
    SMARTLINK: 'smartlink',
    NEWSLETTER: 'newsletter',
    PLAYER: 'player',
    CUSTOM: 'custom',
};

export const LOCATIONS_LABEL = {
    [LINKS_NAMES.PAGE_PODCAST]: <FormattedMessage defaultMessage="Site Web" />,
    [LINKS_NAMES.SMARTLINK]: <FormattedMessage defaultMessage="Smartlink" />,
    [LINKS_NAMES.NEWSLETTER]: <FormattedMessage defaultMessage="Newsletter" />,
    [LINKS_NAMES.PLAYER]: <FormattedMessage defaultMessage="Smartplayer" />,
};

export const BASICS_CHECKBOX = {
    [LINKS_NAMES.PAGE_PODCAST]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.PAGE_PODCAST],
        value: LINKS_NAMES.PAGE_PODCAST,
    },
    [LINKS_NAMES.PLAYER]: { label: LOCATIONS_LABEL[LINKS_NAMES.PLAYER], value: LINKS_NAMES.PLAYER },
    [LINKS_NAMES.SMARTLINK]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.SMARTLINK],
        value: LINKS_NAMES.SMARTLINK,
    },
    [LINKS_NAMES.NEWSLETTER]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.NEWSLETTER],
        value: LINKS_NAMES.NEWSLETTER,
    },
};

export const ICON_LIST_CTA = {
    [LINKS_NAMES.PAGE_PODCAST]: (
        <FontAwesomeIcon
            icon={icon({ name: 'globe', style: 'regular' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.SMARTLINK]: (
        <FontAwesomeIcon
            icon={icon({ name: 'link', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.NEWSLETTER]: (
        <FontAwesomeIcon
            icon={icon({ name: 'envelope-open-text', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.CROWDFUNDING]: (
        <FontAwesomeIcon
            icon={icon({ name: 'heart', style: 'regular' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.CUSTOM]: (
        <FontAwesomeIcon
            icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
};

export const ACTIONS_CTA = {
    OPEN_EDIT_MODAL_CTA: 'open-edit-modal-cta',
    OPEN_CREATE_BUTTON_MODAL_CTA: 'open-create-button-modal-cta',
    OPEN_MENU_DROP_DOWN_CTA: 'open-menu-drop-down-cta',
    DELETE_CTA: 'delete-cta',
    DELETE_LOCATION_CTA: 'delete-location-cta',
};

const LoadingCTAFallback = ({ fallbackList }) => (
    <DeprecatedPaper flex column gap={16} mb={16} align="center">
        <DeprecatedPaper flex column gap={16} mb={16} align="center">
            {fallbackList.map(
                (link) =>
                    link.url && (
                        <DeprecatedPaper
                            background="--neutral50"
                            maxW={680}
                            minH={130}
                            rounded
                            p={12}
                            gap={16}
                            key={link.id}
                        />
                    ),
            )}
        </DeprecatedPaper>
    </DeprecatedPaper>
);

const initialCTAState = {
    item: { text: '', url: '' },
    locationSelected: {},
};

const enhance = connect(({ routerStore, callToActionStore }) => {
    const show = routerStore.resolve('showId');
    return {
        showId: show?.id,
        callToActionButtons: callToActionStore.callToActionButtons,
    };
});

const CTAAccessAllowed = ({ showId, callToActionButtons }) => {
    const [isOpenModal, openModal, closeModal] = useSwitch(false);
    const [isOpenCreateModal, openCreateModal, closeCreateModal] = useSwitch(false);
    const [isOpenDeleteButtonModal, openDeleteButtonModal, closeDeleteButtonModal] =
        useSwitch(false);
    const [isOpenDeleteLocationModal, openDeleteLocationModal, closeDeleteLocationModal] =
        useSwitch(false);

    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS_CTA.OPEN_EDIT_MODAL_CTA: {
                const handleOpenedModal = () => {
                    openModal();
                    if (action.payload.closeDropDownCTA) action.payload.closeDropDownCTA();
                    return { item: { ...action.payload.item }, locationSelected: {} };
                };
                return handleOpenedModal();
            }

            case ACTIONS_CTA.OPEN_MENU_DROP_DOWN_CTA:
                return { item: {}, locationSelected: {}, opened: action.payload.opened() };

            case ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA: {
                const handleCreate = () => {
                    openCreateModal();
                    action.payload.closeDropDownCTA();
                    return { item: { ...action.payload.item }, locationSelected: {} };
                };
                return handleCreate();
            }

            case ACTIONS_CTA.DELETE_CTA: {
                const deleteModal = () => {
                    openDeleteButtonModal();
                    return { item: action.payload.item, locationSelected: {} };
                };
                return deleteModal();
            }

            case ACTIONS_CTA.DELETE_LOCATION_CTA: {
                const deleteLocationModal = () => {
                    openDeleteLocationModal();
                    return {
                        item: action.payload.item,
                        locationSelected: action.payload.locationSelected,
                    };
                };
                return deleteLocationModal();
            }

            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialCTAState, undefined);

    return (
        <CTAWrapper>
            <CTAInnerWrapper>
                <FetcherPageSettings showId={showId} />
                <FetcherShowTemplate provider="newsletter" showId={showId} />
                <FetcherCallToActionButtons showId={showId}>
                    {(isLoading) =>
                        isLoading ? (
                            <LoadingCTAFallback fallbackList={callToActionButtons} />
                        ) : (
                            <>
                                <CTAAddNewButtonModal
                                    state={state}
                                    checkboxList={BASICS_CHECKBOX}
                                    isOpened={isOpenModal}
                                    onClose={closeModal}
                                />
                                <CTACreateButtonModal
                                    state={state}
                                    checkboxList={BASICS_CHECKBOX}
                                    isOpened={isOpenCreateModal}
                                    onClose={closeCreateModal}
                                />
                                <CTADeleteButtonModal
                                    state={state}
                                    isOpened={isOpenDeleteButtonModal}
                                    onClose={closeDeleteButtonModal}
                                />
                                <CTADeleteLocationModal
                                    state={state}
                                    isOpened={isOpenDeleteLocationModal}
                                    onClose={closeDeleteLocationModal}
                                />
                                <CTAControlPanel dispatch={dispatch} isLoading={isLoading} />
                            </>
                        )
                    }
                </FetcherCallToActionButtons>
            </CTAInnerWrapper>
        </CTAWrapper>
    );
};

const CTAWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const CTAInnerWrapper = styled.div`
    margin-inline: auto;
`;

export default enhance(CTAAccessAllowed);
