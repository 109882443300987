import { useMutation } from '@tanstack/react-query';
import { updatePodcast } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
    episode: {
        canDownload?: boolean;
        customUrl?: string;
        description?: string;
        htmlDescription?: string;
        isExplicit?: boolean;
        name?: string;
        privacy?: 'public' | 'unlisted' | 'private';
        publishYoutube?: boolean;
        publishedAt?: string;
        seasonId?: string;
        showId?: number;
        slug?: string;
        state?: 'draft' | 'blocked' | 'scheduled' | 'active';
        tags?: string[];
        type?: 'full' | 'bonus' | 'trailer';
        useCustomUrl?: boolean;
    };
}

const mutationFn = async ({ episodeId, episode }: MutationFnProps) => {
    const { data } = await updatePodcast(episodeId, decamelizeKeys(episode));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function useUpdateEpisodeMutation() {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Invalidation of episode query is handled in useSaveEpisodeFormMutation
        },
    });
}
