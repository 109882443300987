import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TranscriptionWaveform from '@app/molecules/TranscriptionPlayer/TranscriptionWaveform/TranscriptionWaveform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSwitch } from '@hooks/useSwitch';
import { formatTime } from '@/helpers';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import IconSkipBackwardFifteen from '@ui/icons/IconSkipBackwardFifteen';
import IconSkipForwardFifteen from '@ui/icons/IconSkipForwardFifteen';
import TranscriptionPlayerAudioRateMenu from './TranscriptionPlayerAudioRateMenu';
import { Button as RACButton, DialogTrigger, Popover } from 'react-aria-components';

const SKIP_FORWARD = 'forward';
const SKIP_BACKWARD = 'backward';

interface TranscriptionPlayerProps {
    audioUrl: string;
    waveformDataUrl: string;
    cursorTimeCode: number;
    onCurrentTimeChange: (currentTime: number) => void;
    onPlay: () => void;
}

const TranscriptionPlayer = ({
    audioUrl,
    waveformDataUrl,
    cursorTimeCode,
    onCurrentTimeChange,
    onPlay,
}: TranscriptionPlayerProps) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [audioFileDuration, setAudioFileDuration] = useState(0);
    const [isPlaying, play, pause] = useSwitch(false);
    const [audioRate, setAudioRate] = useState(1);
    const [isAudioRateMenuOpen, setIsAudioRateMenuOpen] = useState(false);
    const audioRateTriggerRef = useRef(null);

    const handleSkipInAudio = (type: string) => {
        const previousValue = currentTime;
        const newValue =
            type === SKIP_FORWARD
                ? Math.min(previousValue + 15, audioFileDuration)
                : Math.max(previousValue - 15, 0);
        setCurrentTime(newValue);
        setCursorPosition(newValue);
    };

    const handlePlay = () => {
        onPlay();
        play();
    };

    const handlePlayOnTime = (time: number) => {
        setCurrentTime(time);
        setCursorPosition(time);
        play();
    };

    useEffect(() => {
        onCurrentTimeChange(currentTime);
    }, [currentTime]);

    useEffect(() => {
        if (cursorTimeCode) handlePlayOnTime(cursorTimeCode);
    }, [cursorTimeCode]);

    return (
        <Container>
            <ControlsWrapper>
                <Controls>
                    <SkipButton
                        //@ts-ignore
                        variant="secondary"
                        onPress={() => handleSkipInAudio(SKIP_BACKWARD)}
                        startIcon={<IconSkipBackwardFifteen />}
                    />
                    {isPlaying ? (
                        <IconPlayPause
                            icon={icon({ name: 'pause', style: 'solid' })}
                            onClick={() => (isPlaying ? pause() : handlePlay())}
                        />
                    ) : (
                        <IconPlayPause
                            icon={icon({ name: 'play', style: 'solid' })}
                            onClick={() => (isPlaying ? pause() : handlePlay())}
                        />
                    )}
                    <SkipButton
                        //@ts-ignore
                        variant="secondary"
                        onPress={() => handleSkipInAudio(SKIP_FORWARD)}
                        startIcon={<IconSkipForwardFifteen />}
                    />
                    {/* <DialogTrigger> */}
                    <AudioRateButton
                        ref={audioRateTriggerRef}
                        onPress={() => setIsAudioRateMenuOpen(true)}
                    >
                        x{audioRate}
                    </AudioRateButton>
                    <Popover
                        triggerRef={audioRateTriggerRef}
                        isOpen={isAudioRateMenuOpen}
                        onOpenChange={setIsAudioRateMenuOpen}
                        placement="bottom left"
                    >
                        <TranscriptionPlayerAudioRateMenu
                            value={audioRate}
                            onChange={setAudioRate}
                            onClose={() => setIsAudioRateMenuOpen(false)}
                        />
                    </Popover>
                    {/* </DialogTrigger> */}
                </Controls>
                <TimeCodes>
                    <TimeCodeText>{formatTime(currentTime)}</TimeCodeText>
                    <TimeCodeText color="--neutral500">/</TimeCodeText>
                    <TimeCodeText color="--neutral500">
                        {formatTime(audioFileDuration)}
                    </TimeCodeText>
                </TimeCodes>
            </ControlsWrapper>
            <TranscriptionWaveform
                audioUrl={audioUrl}
                waveformDataUrl={waveformDataUrl}
                isPlaying={isPlaying}
                cursorPosition={cursorPosition}
                onCurrentTimeChange={setCurrentTime}
                onDurationChange={setAudioFileDuration}
                audioRate={audioRate}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    background: white;
    padding: 1rem;
    border-radius: var(--r-l);
    box-shadow: var(--s-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;
    }
`;
const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: column;
        justify-content: center;
        row-gap: 0.5rem;
    }
`;
const Controls = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const TimeCodes = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const SkipButton = styled(Button)`
    padding: 0;
    border: none;
    background: transparent;
`;
const IconPlayPause = styled(FontAwesomeIcon)`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
`;
const TimeCodeText = styled(Text)`
    font-variant-numeric: tabular-nums;
`;
const AudioRateButton = styled(RACButton)`
    background-color: var(--primary50);
    color: var(--primary500);
    padding-block: 0.25rem;
    border-radius: var(--r-s);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;

TranscriptionPlayer.propTypes = {
    audioUrl: PropTypes.string,
    waveformDataUrl: PropTypes.string,
    segmentTimeCode: PropTypes.number,
    onCurrentTimeChange: PropTypes.func,
    onPlay: PropTypes.func,
};

export default TranscriptionPlayer;
