import { FormattedMessage } from 'react-intl';
import Error from '../Error';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const UnexpectedError = () => {
    const history = useHistory();

    const handleGoBack = () => {
        if (history.length > 2) {
            history.goBack();
            return;
        }
        history.replace('/');
    };

    return (
        <Error
            title={<FormattedMessage defaultMessage="Oups! Quelque chose s'est mal passé" />}
            subtitle={
                <FormattedMessage defaultMessage="Notre petit robot a rencontré une erreur inattendue..." />
            }
            message={
                <FormattedMessage defaultMessage="Veuillez rafraîchir la page ou réessayer dans quelques minutes. Nous nous excusons pour le désagrément. 🙏" />
            }
            action={
                <Button
                    onPress={handleGoBack}
                    startIcon={<ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                >
                    <FormattedMessage defaultMessage="Retour arrière" />
                </Button>
            }
        />
    );
};

const ArrowIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default UnexpectedError;
