import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, useAnimationControls, AnimatePresence } from 'framer-motion';
import O5H4 from '@public/images/transcription-bot.png';
import heart from '@public/images/heart.png';

interface SuperSecretComponentProps {
    shouldNowHideSuperSecretComponent: () => void;
}

const SuperSecretComponent = ({ shouldNowHideSuperSecretComponent }: SuperSecretComponentProps) => {
    const [exitAnimationTriggered, setExitAnimationTriggered] = useState<boolean>(false);
    const animationControlsEyes = useAnimationControls();

    const sequenceEyes = async () => {
        await animationControlsEyes.start({ scale: 1 });
        await animationControlsEyes.start({ x: -10 });
        await animationControlsEyes.start({ x: 10 });
        await animationControlsEyes.start({ x: -20 });
        await animationControlsEyes.start({ height: '6px' });
        await animationControlsEyes.start({ height: '17px' });
        await animationControlsEyes.start({ x: 0 });
        await animationControlsEyes.start({ x: 5, y: 10 });
        await animationControlsEyes.start({
            x: -5,
            y: -10,
            rotate: -10,
            transition: { ease: 'linear', duration: 1.5 },
        });
        await animationControlsEyes.start({
            x: -10,
            y: 0,
            rotate: 0,
        });
        await animationControlsEyes.start({
            height: '6px',
            transition: { duration: 0.075 },
        });
        await animationControlsEyes.start({ y: -4, transition: { duration: 0.075 } });
        await animationControlsEyes.start({ y: 0, transition: { duration: 0.075 } });
        await animationControlsEyes.start({ y: -4, transition: { duration: 0.075 } });
        await animationControlsEyes.start({ y: 0, transition: { duration: 0.075 } });
        await animationControlsEyes.start({ y: -4, transition: { duration: 0.075 } });
        await animationControlsEyes.start({ y: 0, transition: { duration: 0.075 } });
        await animationControlsEyes.start({
            x: -10,
            y: 0,
            rotate: 0,
            transition: { delay: 0.2 },
        });
        setExitAnimationTriggered(true);
        setTimeout(() => {
            shouldNowHideSuperSecretComponent();
        }, 1000);
    };

    useEffect(() => {
        sequenceEyes();
    }, []);

    return (
        <OuterSuperSecretWrapper>
            <AnimatePresence initial={true}>
                {!exitAnimationTriggered && (
                    <SuperSecretWrapper
                        key="secret"
                        initial={{ y: '10vh' }}
                        animate={{ y: 0, delay: 1 }}
                        exit={{ y: '10vh' }}
                        transition={{ duration: 0.5 }}
                    >
                        <Body src={O5H4} />
                        <Eyes
                            width="26"
                            height="25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            animate={animationControlsEyes}
                        >
                            <g fill="#fff" stroke="#fff" strokeWidth=".5">
                                <LeftEye
                                    x="4.708"
                                    y=".861"
                                    width="4.408"
                                    height="15.738"
                                    rx="2.204"
                                    transform="rotate(-1.081 4.708 .861)"
                                />
                                <RightEye
                                    x="16.677"
                                    y=".861"
                                    width="4.408"
                                    height="15.738"
                                    rx="2.204"
                                    transform="rotate(-1.081 16.677 .861)"
                                />
                            </g>
                        </Eyes>
                    </SuperSecretWrapper>
                )}
            </AnimatePresence>
            <Heart
                src={heart}
                initial={{ opacity: 1 }}
                animate={{ opacity: 0, y: -120 }}
                transition={{ duration: 2.6, delay: 1.7 }}
            />
        </OuterSuperSecretWrapper>
    );
};
const OuterSuperSecretWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 14rem;
    width: 8rem;
    height: 4.5rem;
`;
// @ts-ignore
const SuperSecretWrapper = styled(motion.div)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;
const Body = styled.img`
    max-width: 8rem;
    height: auto;
`;
// @ts-ignore
const Eyes = styled(motion.svg)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const LeftEye = styled.rect``;
const RightEye = styled.rect``;
// @ts-ignore
const Heart = styled(motion.img)`
    z-index: 2;
    position: absolute;
    right: -1rem;
    bottom: -3rem;
`;

export default SuperSecretComponent;
