import { css } from 'styled-components';
import {
    buttonDisabledStyles,
    buttonFocusVisibleStyles,
    buttonFontStyles,
    buttonHoveredStyles,
    buttonLayoutStyles,
    buttonLoadingStyles,
    buttonPressedStyles,
    buttonResetStyles,
    buttonTransitionStyles,
} from '../base';

export const tertiaryButtonStyles = css`
    ${buttonResetStyles}
    ${buttonFontStyles}
    ${buttonTransitionStyles}
    ${buttonLoadingStyles}
    ${buttonDisabledStyles}
    ${buttonHoveredStyles}
    ${buttonPressedStyles}
    ${buttonFocusVisibleStyles}
    ${buttonLayoutStyles}

    color: var(--black);
    background: transparent;
    border: 1px solid var(--neutral200);

    &:hover {
        border-color: var(--neutral300);
        background-color: var(--neutral50);
    }
    &[data-hovered] {
        border-color: var(--neutral300);
        background-color: var(--neutral50);
    }
`;
