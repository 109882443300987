import { useEffect, useState } from 'react';
import EpisodeAIPanelField from '@/components/EpisodeAI/EpisodeAIPanel/EpisodeAIPanelField';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EpisodeAIOverwriteWarningModal from '@/components/EpisodeAI/EpisodeAIOverwriteWarningModal';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import useAIDescriptionSuggestionsQuery from '@queries/ai/useAIDescriptionSuggestionQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { useEpisodeAISettings } from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';
import useAISuggestionsError from '../utils/useAISuggestionsError.hook';
import useHasAccessToEpisodeAI from '@/utils/hooks/useHasAccessToEpisodeAI.hook';

interface EpisodeAIDescriptionFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAIDescriptionField = ({
    generateButtonDataTestId,
}: EpisodeAIDescriptionFieldProps) => {
    const { episodeId, showId } = useParams<{ episodeId: string; showId: string }>();
    const [settings] = useEpisodeAISettings();
    const descriptionSettings = settings.get('description') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const descriptionSuggestionsQuery = useAIDescriptionSuggestionsQuery({
        episodeId,
        settings: descriptionSettings,
        delay: 2000,
    });
    const { getValues } = useFormContext();
    const description = getValues('content.htmlDescription') || '';
    const isHtmlDescriptionEmpty = /^(<p>(<br>)?<\/p>)+$/.test(description);
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-description`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('description') || false;
    const episode = useEpisodeQuery({ episodeId, config: { enabled: !!episodeId } });
    const hasAccessToAI = useHasAccessToEpisodeAI({ showId });
    const isDisabled = isGenerated && episode.data?.aiFreeTrialEnabled && !hasAccessToAI;

    useAISuggestionsError({
        isError: descriptionSuggestionsQuery.isError,
        episodeId,
        type: 'description',
    });

    // Once the description suggestions are fetched, set the generated flag to true
    useEffect(() => {
        if (descriptionSuggestionsQuery.data && !descriptionSuggestionsQuery.isFetching) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-description`, 'true');
            setIsGenerated(true);
        }
    }, [descriptionSuggestionsQuery.data, descriptionSuggestionsQuery.isFetching]);

    const handleOpenModal = () => {
        if (!isHtmlDescriptionEmpty && description?.length > 0) {
            setIsModalOpen(true);
            return;
        }
        handleGenerate();
    };

    const handleGenerate = () => {
        setIsModalOpen(false);
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'description',
        });
        descriptionSuggestionsQuery.refetch();
    };

    return (
        <>
            <EpisodeAIPanelField
                data-testid={generateButtonDataTestId}
                icon={<Icon icon={icon({ name: 'align-left', style: 'solid' })} />}
                type="description"
                title={<FormattedMessage defaultMessage="Description" />}
                description={
                    <FormattedMessage defaultMessage="Un résumé exhaustif et impactant de votre épisode" />
                }
                onAction={handleOpenModal}
                isLoading={isLoading}
                isGenerated={isGenerated && !isLoading}
                isDisabled={isDisabled}
                tooltip="💎 Add-on"
            />
            <EpisodeAIOverwriteWarningModal
                isOpen={isModalOpen}
                onOpenChange={setIsModalOpen}
                onAction={handleGenerate}
                actionTestId="episode-ai-description-overwrite-modal-button"
                message={
                    <FormattedMessage defaultMessage="La génération AI écrasera la description actuelle" />
                }
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAIDescriptionField;
