import { useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';

export function useCopy() {
    const [copied, onCopy] = useState(false);

    function copy(text) {
        onCopy(true);
        copyToClipboard(text);
        setTimeout(() => onCopy(false), 2000);
    }

    return [copied, copy];
}
