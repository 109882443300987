import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';

export interface PricingOfferFeatureProps {
    isHighlighted?: boolean;
    isAdditional?: boolean;
    isMagic?: boolean;
    iconColor?: string;
    children: React.ReactNode;
}

const PricingOfferFeature = ({
    isHighlighted,
    isAdditional,
    isMagic,
    iconColor,
    children,
}: PricingOfferFeatureProps) => (
    <PricingOfferFeatureWrapper>
        {isAdditional ? (
            <FeatureIcon
                icon={icon({ name: 'plus', style: 'solid' })}
                iconColor={iconColor ? iconColor : '--primary'}
            />
        ) : isMagic ? (
            <Sparkles />
        ) : (
            <FeatureIcon
                icon={icon({ name: 'check', style: 'solid' })}
                iconColor={iconColor ? iconColor : '--success'}
            />
        )}
        <FeatureText fontWeight={isHighlighted ? '--fw-bold' : '--fw-normal'} $isMagic={isMagic}>
            {children}
        </FeatureText>
    </PricingOfferFeatureWrapper>
);

const PricingOfferFeatureWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
    align-items: center;
`;
const FeatureIcon = styled(FontAwesomeIcon)<{ iconColor: string }>`
    color: ${(props) => props.iconColor && `var(${props.iconColor})`};
    margin-top: 0.125rem;
    font-size: 0.75rem;
`;
const Sparkles = styled(SparklesIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const FeatureText = styled(Text)<{ $isMagic: boolean }>`
    background: ${(props) => props.$isMagic && `var(--gradient-nebula)`};
    background-clip: ${(props) => props.$isMagic && `text`};
    color: ${(props) => props.$isMagic && `transparent`}; ;
`;

export default PricingOfferFeature;
