import { sendAmplitudeLogEvent } from '@/helpers';
import { useEffect } from 'react';
import { AISettingsMapItem } from './contexts/EpisodeAISettingsContext';

interface AIRegenerationLoggerProps {
    suggestions: string[] | string | undefined;
    field: AISettingsMapItem;
    episodeId: string;
    prompt: string;
}

export const useAIRegenerationLogger = ({
    suggestions,
    field,
    episodeId,
    prompt,
}: AIRegenerationLoggerProps) => {
    useEffect(() => {
        if (!suggestions) return;
        const isRegenerated =
            localStorage.getItem(`episode-ai-${episodeId}-regenerated-${field}`) === 'true';
        if (!isRegenerated) return;
        sendAmplitudeLogEvent('AI Regeneration', {
            field,
            suggestions,
            prompt,
        });
    }, [suggestions]);
};
