import CircularProgress from '@/components/ui/atoms/CircularProgress';
import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { usePsoScoreContext } from '@/context/PsoScoreContext';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const PsoScoreSummary = () => {
    const { psoScore } = usePsoScoreContext();
    const score = psoScore ?? 0;
    const scoreThresholds = [
        {
            threshold: 0,
            color: '--alert',
            title: <FormattedMessage defaultMessage="Faible" />,
        },
        {
            threshold: 25,
            color: '--warning',
            title: <FormattedMessage defaultMessage="Moyen" />,
        },
        {
            threshold: 70,
            color: '--success',
            title: <FormattedMessage defaultMessage="Élevé" />,
        },
    ];

    let scoreIndex = 0;
    for (let i = 0; i < scoreThresholds.length; i++) {
        if (scoreThresholds[i].threshold <= score) {
            scoreIndex = i;
        } else {
            break;
        }
    }

    const reachedThreshold = scoreThresholds[scoreIndex];

    return (
        <Summary $color={reachedThreshold.color}>
            <Text color="--black" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="PSO Score" />
            </Text>
            <Cluster $gap="0.5rem" $align="center">
                <CircularProgress
                    size={20}
                    percentage={score}
                    backgroundColor={`${reachedThreshold.color}100`}
                    progressColor={reachedThreshold.color}
                    strokeWidth={4}
                />
                <Text fontWeight="--fw-semibold">
                    {reachedThreshold.title} ({score}%)
                </Text>
            </Cluster>
        </Summary>
    );
};

const Summary = styled.div<{ $color: string }>`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: space-between;
    border-radius: 0.75rem;
    padding: 1rem;
    background-color: ${({ $color }) => `var(${$color}50)`};
    color: ${({ $color }) => `var(${$color}500)`};
`;

export default PsoScoreSummary;
