import { FC, useState, createContext, useContext, ReactNode } from 'react';
import dayjs from 'dayjs';

interface IDateContext {
    startDate: string | null;
    endDate: string | null;
    timezone: string;
    setStartDate: (date: string | null) => void;
    setEndDate: (date: string | null) => void;
}

const StatsDateSelectorContext = createContext<IDateContext | undefined>(undefined);

interface StatsDateSelectorProviderProps {
    children: ReactNode;
}

export const StatsDateSelectorProvider: FC<StatsDateSelectorProviderProps> = ({ children }) => {
    const [startDate, setStartDate] = useState<string | null>(
        dayjs().subtract(1, 'month').startOf('day').format('YYYY-MM-DD'),
    );
    const [endDate, setEndDate] = useState<string | null>(
        dayjs().endOf('day').format('YYYY-MM-DD'),
    );

    const [timezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const value = {
        startDate,
        endDate,
        timezone,
        setStartDate,
        setEndDate,
    };

    return (
        <StatsDateSelectorContext.Provider value={value}>
            {children}
        </StatsDateSelectorContext.Provider>
    );
};

export const useStatsDateSelectorContext = () => {
    const context = useContext(StatsDateSelectorContext);
    if (context === undefined) {
        throw new Error('useDateContext must be used within a DateProvider');
    }
    return context;
};
