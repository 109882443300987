import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import SuperSecretComponent from './SuperSecretComponent';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Footer = () => {
    const intl = useIntl();
    const [hoverTimeoutId, setHoverTimeoutId] = useState<number>();
    const [isSuperSecretComponentVisible, setIsSuperSecretComponentVisible] =
        useState<boolean>(false);

    const SUPER_SECRET_FEATURE_TIMEOUT_DELAY = 4000;

    const hasHover = window.matchMedia('(hover: hover)').matches;
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

    const handleMouseEnter = () => {
        if (!hasHover || prefersReducedMotion) return;
        if (hoverTimeoutId) window.clearTimeout(hoverTimeoutId);
        const id = window.setTimeout(() => {
            setIsSuperSecretComponentVisible(true);
        }, SUPER_SECRET_FEATURE_TIMEOUT_DELAY);
        setHoverTimeoutId(id);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeoutId);
    };

    useEffect(() => {
        return () => {
            if (hoverTimeoutId) {
                clearTimeout(hoverTimeoutId);
            }
        };
    }, [hoverTimeoutId]);

    return (
        <>
            <FooterWrapper>
                <AnimatePresence initial={false}>
                    {!isSuperSecretComponentVisible ? (
                        <MadeWithLove
                            key="madewithlove"
                            href="https://www.ausha.co/"
                            target="_BLANK"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FormattedMessage
                                defaultMessage="Fait avec {love} par Ausha"
                                values={{
                                    love: (
                                        <LoveIcon icon={icon({ name: 'heart', style: 'solid' })} />
                                    ),
                                }}
                            />
                        </MadeWithLove>
                    ) : (
                        <FlexDecoy />
                    )}
                </AnimatePresence>
                <Menu>
                    <MenuItem>
                        <MenuLink
                            href={intl.formatMessage({
                                defaultMessage: 'https://www.ausha.co/fr/mentions-legales/',
                            })}
                            target="_BLANK"
                        >
                            <FormattedMessage defaultMessage="Mentions Légales" />
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            href={intl.formatMessage({
                                defaultMessage:
                                    'https://www.ausha.co/fr/politique-de-confidentialite/',
                            })}
                            target="_BLANK"
                        >
                            <FormattedMessage defaultMessage="Politique de confidentialité" />
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            href={intl.formatMessage({
                                defaultMessage: 'https://www.ausha.co/fr/nos-cgu/',
                            })}
                            target="_BLANK"
                        >
                            <FormattedMessage defaultMessage="CGU" />
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            href={intl.formatMessage({
                                defaultMessage: 'https://www.ausha.co/fr/nos-cga/',
                            })}
                            target="_BLANK"
                        >
                            <FormattedMessage defaultMessage="CGA" />
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink
                            href={intl.formatMessage({
                                defaultMessage: 'https://status.ausha.co/',
                            })}
                            target="_BLANK"
                        >
                            <FormattedMessage defaultMessage="Statut" />
                        </MenuLink>
                    </MenuItem>
                </Menu>
            </FooterWrapper>
            {isSuperSecretComponentVisible && (
                <SuperSecretComponent
                    shouldNowHideSuperSecretComponent={() => {
                        setIsSuperSecretComponentVisible(false);
                    }}
                />
            )}
        </>
    );
};

const FooterWrapper = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 7.5rem;
    padding-top: 2rem;
    padding-bottom: 1rem;

    &:first-child {
        flex-grow: 1;
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding-inline: 0.5rem;
        gap: 0.5rem;
    }
`;
const FlexDecoy = styled.div``;
const LoveIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    opacity: 0.4;
`;
const heartbeat = keyframes`
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
`;

// @ts-ignore
const MadeWithLove = styled(motion.a)`
    color: var(--neutral500);
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: var(--neutral700);
    }
    &:hover > ${LoveIcon} {
        transition-duration: 0.25s;
        opacity: 1;
        animation: ${heartbeat} 1.5s ease-in-out infinite both;
    }
    @media (max-width: 768px) {
        font-size: var(--fs-body-xs);
    }
`;
const Menu = styled.ul`
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const MenuItem = styled.li`
    flex-shrink: 0;
    color: var(--neutral500);
    @media (max-width: 768px) {
        font-size: var(--fs-body-xs);
    }
`;
const MenuLink = styled.a`
    text-decoration: none;
    color: inherit;
    &:hover {
        color: var(--neutral700);
    }
`;

export default Footer;
