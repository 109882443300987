import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadEpisodeYoutubeThumbnail } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import youtubeVideoKeys from '@/queries/youtubeVideo/youtubeVideoKeys';

export interface MutationFnProps {
    episodeId: string;
    image: File;
}

const mutationFn = async ({ episodeId, image }: MutationFnProps) => {
    const { data } = await uploadEpisodeYoutubeThumbnail(episodeId, image);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAddEpisodeYoutubeThumbnailMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: youtubeVideoKeys.detailById(variables.episodeId),
            });
            // Invalidation of episode query is handled in useSaveEpisodeFormMutation
        },
    });
};

export default useAddEpisodeYoutubeThumbnailMutation;
