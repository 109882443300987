import Footer from '@/components/Footer';
import UnexpectedError from '@/components/UnexpectedError';
import styled from 'styled-components';

const ErrorPage = () => {
    return (
        <Page>
            <Main>
                <UnexpectedError />
            </Main>
            <Footer />
        </Page>
    );
};
const Page = styled.section`
    width: 100vw;
    min-height: 100dvh;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-inline: 1rem;
`;
const Main = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;

export default ErrorPage;
