import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShowQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import subscriptionKeys from '../subscription/subscriptionKeys';

const transform = (data: any) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

// 0: cancel, 1: subscribe
const mutationFn = async (quantity: 0 | 1) => {
    const { data } = await updateShowQuota({
        item: 'episode_ai',
        quantity,
    });

    return transform(data);
};

const updateAIQuotaMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: subscriptionKeys.detail(),
            });
        },
    });
};

export default updateAIQuotaMutation;
