import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as amplitude from '@amplitude/analytics-browser';
import { FormattedMessage } from 'react-intl';
import { IMG_TYPES, ACCEPTED_AUDIO_TYPES } from './utils/constants';
import { queryClient } from '@/components/Root';
import userKeys from './queries/user/userKeys';

dayjs.extend(objectSupport);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const compose = (...fns) =>
    fns.reduce(
        (f, g) =>
            (...xs) =>
                f(g(...xs)),
        (x) => x,
    );
export const composeDecorators = (...fns) =>
    fns.reduce(
        (acc, f) => (t, k, d) => acc(t, k, f(t, k, d)),
        (t, k, d) => d,
    );

export const priceValueToInt = (str) => {
    if (!str) return 0;
    return typeof str === 'string' ? parseInt(str.replace(/[^0-9]+/, ''), 10) : str;
};
export const isNegativePrice = (str) => parseInt(str, 10) <= 0;

export function timeToArray(secsOrMin, useSecs = true) {
    if (!useSecs) {
        return [Math.floor(secsOrMin / 60), secsOrMin % 60];
    }
    const hours = Math.floor(secsOrMin / 3600);
    const minutes = Math.floor(secsOrMin / 60) - 60 * hours;
    const seconds = Math.floor(secsOrMin) - 60 * minutes - 3600 * hours;
    return [hours, minutes, seconds];
}

export function formatDuration(
    seconds,
    { h = 'h', mn = 'mn', sec = 'sec', join = ' ', full = false, initialValue = false } = {},
) {
    let [hours, minutes, secs] = timeToArray(seconds);

    const strings = [];
    if (hours > 0 || full) {
        strings.push(`${hours}${h}`);
    }
    if (minutes > 0 || full) {
        minutes = hours > 0 || full ? `${minutes}`.padStart(2, '0') : minutes;
        strings.push(`${minutes}${mn}`);
    }
    if (hours === 0 || full) {
        secs = minutes > 0 || full ? `${secs}`.padStart(2, '0') : secs;
        strings.push(`${secs}${sec}`);
    }

    if (!strings.join(join) && initialValue) return seconds;
    return strings.join(join);
}

export function formatDurationParts(seconds) {
    let [hours, minutes, secs] = timeToArray(seconds);

    const values = [];
    if (hours > 0) {
        values.push({ unit: 'hours', value: hours, shortunit: 'h' });
    }
    if (minutes > 0) {
        minutes = hours > 0 ? `${minutes}`.padStart(2, '0') : minutes;
        values.push({ unit: 'minutes', value: minutes, shortunit: 'mn' });
    }
    if (hours === 0) {
        seconds = minutes > 0 ? `${secs}`.padStart(2, '0') : secs;
        values.push({ unit: 'seconds', value: seconds, shortunit: 'sec' });
    }

    return values;
}

export function formatSecondsDuration(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Duration string format can be a number of seconds, or "mm:ss", or "hh:mm:ss"
 */
export function durationToSeconds(duration) {
    const [, h, m, s] = duration.match(/(?:(?:(\d+):)?(\d+):)?(\d+)$/) || [];
    return dayjs.duration({ h, m, s }).asSeconds();
}

export const secondsToFormattedDuration = (seconds) =>
    dayjs.utc(seconds * 1000).format(seconds >= 3600 ? 'HH[h] mm[min] ss[sec]' : 'mm[min] ss[sec]');

export function secondsToDuration(seconds) {
    return dayjs.utc(seconds * 1000).format(seconds >= 3600 ? 'HH:mm:ss' : 'mm:ss');
}

export function formatDateForApi(date) {
    if (!date) return null;
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateForApiUTC(date) {
    if (!date) return null;

    return dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss');
}

export function download(data, filename, type) {
    const file = new Blob([data], { type });
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(file, filename);
        return;
    }

    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }, 0);
}

export function round(value) {
    // Skip the decimal part to check if a specific rounding is required
    const int = Math.floor(value);

    if (int < 1) {
        // Round keeping one decimal (0.734 -> 0.7)
        return Math.round(value * 10) / 10;
    }

    // Round keeping no decimal (12.734 -> 13)
    return Math.round(value);
}

export function formatDates(calendar, params) {
    const months = [
        <FormattedMessage key="jan" defaultMessage="Janvier" />,
        <FormattedMessage key="feb" defaultMessage="Février" />,
        <FormattedMessage key="mar" defaultMessage="Mars" />,
        <FormattedMessage key="apr" defaultMessage="Avril" />,
        <FormattedMessage key="may" defaultMessage="Mai" />,
        <FormattedMessage key="jun" defaultMessage="Juin" />,
        <FormattedMessage key="jul" defaultMessage="Juillet" />,
        <FormattedMessage key="aug" defaultMessage="Août" />,
        <FormattedMessage key="sep" defaultMessage="Septembre" />,
        <FormattedMessage key="oct" defaultMessage="Octobre" />,
        <FormattedMessage key="nov" defaultMessage="Novembre" />,
        <FormattedMessage key="dec" defaultMessage="Décembre" />,
    ];
    const weeks = [
        <FormattedMessage key="mon" defaultMessage="Lundi" />,
        <FormattedMessage key="tue" defaultMessage="Mardi" />,
        <FormattedMessage key="wed" defaultMessage="Mercredi" />,
        <FormattedMessage key="thu" defaultMessage="Jeudi" />,
        <FormattedMessage key="fri" defaultMessage="Vendredi" />,
        <FormattedMessage key="sat" defaultMessage="Samedi" />,
        <FormattedMessage key="sun" defaultMessage="Dimanche" />,
    ];
    const times = {
        hour: <FormattedMessage defaultMessage="Heure" />,
        minute: <FormattedMessage defaultMessage="Minute" />,
    };
    const range = {
        days: <FormattedMessage defaultMessage="Jour" />,
        weeks: <FormattedMessage defaultMessage="Semaine" />,
        months: <FormattedMessage defaultMessage="Mois" />,
        years: <FormattedMessage defaultMessage="Année" />,
    };
    const calendarChoice = {
        months: months[params],
        weeks: weeks[params],
        range: range[params],
        times: times[params],
    };
    return calendarChoice[calendar];
}

export function commonMessages(message, params) {
    const idealSizeImg = {
        background: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1500*1800px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        imageMobile: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1400*1400px {lineBreak}Taille min : 400*400px {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        image: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1400*1400px {lineBreak}Taille min : 400*400px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        favicon: (
            <>
                <FormattedMessage
                    defaultMessage="Taille idéale : 32*32px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                    values={{ lineBreak: <br /> }}
                />
            </>
        ),
        logo: (
            <FormattedMessage
                defaultMessage="Taille idéale : 400*120px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{ lineBreak: <br /> }}
            />
        ),
        uploadImage: <FormattedMessage defaultMessage="Importer une image" />,
        deletedImage: <FormattedMessage defaultMessage="Supprimer l'image" />,
    };

    const messagesStatus = {
        online: <FormattedMessage defaultMessage="Public" />,
        published: <FormattedMessage defaultMessage="Publié" />,
        blocked: <FormattedMessage defaultMessage="Bloqué" />,
        planned: <FormattedMessage defaultMessage="Planifié" />,
        scheduled: <FormattedMessage defaultMessage="Planifié" />,
        draft: <FormattedMessage defaultMessage="Brouillon" />,
        private: <FormattedMessage defaultMessage="Privé" />,
        unlisted: <FormattedMessage defaultMessage="Non-listé" />,
        ended: <FormattedMessage defaultMessage="Terminé" />,
        done: <FormattedMessage defaultMessage="Publié" />,
        publish_at: <FormattedMessage defaultMessage="Planifié" />,
        error: <FormattedMessage defaultMessage="Erreur" />,
        pending: <FormattedMessage defaultMessage="En attente..." />,
        processing: <FormattedMessage defaultMessage="En cours" />,
    };

    const messagePrivacy = {
        public: <FormattedMessage defaultMessage="Public" />,
        online: <FormattedMessage defaultMessage="En ligne" />,
        unlisted: <FormattedMessage defaultMessage="Non-listé" />,
        private: <FormattedMessage defaultMessage="Privé" />,
    };

    const clipCover = {
        format: <FormattedMessage defaultMessage="Format : JPG ou PNG" />,
        square: <FormattedMessage defaultMessage="Taille idéale : 1500*1500px" />,
        story: <FormattedMessage defaultMessage="Taille idéale : 1400*2600px" />,
        wide: <FormattedMessage defaultMessage="Taille idéale : 1400*800px" />,
    };

    const messageChoice = {
        idealSizeImg: idealSizeImg[params],
        status: messagesStatus[params],
        privacy: messagePrivacy[params],
        clipCover: clipCover[params],
    };

    return messageChoice[message];
}

export const makeArray = (n, startValue = 0) => Array.from(Array(n), (_, i) => i + startValue);
export const parseIntArray = (eleQuery, splitBy = '_') =>
    eleQuery && eleQuery.split(splitBy).map((ele) => parseInt(ele, 10));
export const sortArrayByAlphabeticalOrder = (array, key) =>
    array.sort((a, b) => a[key] < b[key] && -1);

// to normalize the input type=date we need to format it
// source: https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input/date#valeur
export const formatDateInput = (dataValue) =>
    dayjs({
        year: dataValue.getFullYear(),
        month: dataValue.getMonth(),
        date: dataValue.getDate(),
    }).format('YYYY-MM-DD');

export const formatTime = (time, options) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time - hours * 3600) / 60);
    let seconds = time - hours * 3600 - minutes * 60;

    seconds = options === 'milliseconds' ? seconds.toFixed(3) : Math.round(seconds);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
};

export const formatTimeLight = (time) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time - hours * 3600) / 60);
    let seconds = Math.floor(time - hours * 3600 - minutes * 60);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours > 0 ? hours + ':' + minutes + ':' + seconds : minutes + ':' + seconds;
};

export const statusValue = ({ status, privacy }) => {
    if (status === 'online' && privacy === 'public') return 'online';
    if (status === 'online' && privacy === 'unlisted') return 'unlisted';
    if (status === 'online' && privacy === 'private') return 'private';
    if (status === 'active' && privacy === 'public') return 'online';
    if (status === 'active' && privacy === 'unlisted') return 'unlisted';
    if (status === 'active' && privacy === 'private') return 'private';
    if (status === 'planned') return 'planned';
    if (status === 'scheduled') return 'scheduled';
    if (status === 'pending') return 'pending';
    if (status === 'processing') return 'processing';
    if (status === 'error') return 'error';
    return 'draft';
};

export const computeIntervalBetweenApiCall = (base) => {
    const interval = base * 100;
    if (interval < 2000) return 10000;
    return 20000;
};

export const slugify = (text) =>
    text
        .toString() // Cast to string
        .normalize('NFKD') // Convert accents
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Remove - at the start
        .replace(/-+$/, ''); // Remove - at the end

export const checkAudioFileFormat = (file) => {
    if (!file) return;
    return ACCEPTED_AUDIO_TYPES.includes(file.type);
};

export const checkImgFormat = (file) => {
    if (!file) return;
    return IMG_TYPES.includes(file.type);
};

export const checkImgSize = (img, locale, maxSize = 10) => {
    if (!img) return;
    const denominator = locale === 'fr' ? 1000 * 1000 : 1024 * 1024;
    return (img.size / denominator).toFixed(1) <= maxSize;
};

export const checkImgDimensions = (img, dimensions) => {
    if (!img) return;
    const DIMENSIONS = {
        minWidth: 0,
        minHeight: 0,
        maxWidth: Infinity,
        maxHeight: Infinity,
        ...dimensions,
    };

    return (
        img.width >= DIMENSIONS.minWidth &&
        img.height >= DIMENSIONS.minHeight &&
        img.width <= DIMENSIONS.maxWidth &&
        img.height <= DIMENSIONS.maxHeight
    );
};

export const experimentalCheckImgDimensions = async (file, dimensions) => {
    if (!(file instanceof File)) {
        console.error("'file' must be an instance of File");
        return false;
    }

    const img = new Image();
    img.src = URL.createObjectURL(new File([file], 'pic'));

    return new Promise((resolve) => {
        img.onload = () => {
            const DIMENSIONS = { minWidth: 400, minHeight: 400, ...dimensions };
            const isValid = img.width >= DIMENSIONS.minWidth && img.height >= DIMENSIONS.minHeight;
            resolve(isValid);
        };
    });
};

export const sendAmplitudeLogEvent = (event, properties = {}, condition = true) => {
    if (condition) {
        amplitude.track(event, properties);
    }
};
export const getDirtyValues = (dirtyFields, allValues) => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted,
    // because there's no way to indicate "placeholders" for unchanged elements.
    // `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // Here, we have an object.
    return Object.fromEntries(
        Object.entries(dirtyFields).map(([key, value]) => [
            key,
            typeof value === 'object' ? getDirtyValues(value, allValues[key]) : allValues[key],
        ]),
    );
};
export const filterFalsyValues = (obj) => {
    if (Array.isArray(obj)) {
        // If it's an array, filter each element recursively
        const filteredArray = obj
            .map((item) => filterFalsyValues(item))
            .filter((item) => item !== undefined && item !== null);
        return filteredArray.length === 0 ? undefined : filteredArray;
    }

    if (typeof obj === 'object' && obj !== null) {
        // If it's an object, filter its properties recursively
        const filteredObj = {};
        for (const [key, value] of Object.entries(obj)) {
            const filteredValue = filterFalsyValues(value);
            if (filteredValue !== undefined && filteredValue !== null) {
                filteredObj[key] = filteredValue;
            }
        }

        // Check if all values in the object are falsy (or undefined/null)
        const allValuesFalsy = Object.values(filteredObj).every((value) => !value);
        return allValuesFalsy ? undefined : filteredObj;
    }

    // If it's a non-object value, return it if it's truthy, otherwise, exclude it
    return obj || undefined;
};

export const getPlainText = (html) => {
    // TODO: Using a different technique than Tiptap to compute text length could
    // result to a different result. Maybe use Tiptap directly to have the same count
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const textContent = tempElement.textContent;
    tempElement.remove();
    return textContent;
};

export const formatPrice = (price, currency) => {
    if (currency === '€') {
        return (
            <>
                <span>{price}</span>
                <span>{currency}</span>
            </>
        );
    }
    if (['$', '£'].includes(currency)) {
        return (
            <>
                <span>{currency}</span>
                <span>{price}</span>
            </>
        );
    }

    return (
        <>
            <span>{price}</span>
            <span>{currency}</span>
        </>
    );
};

export const jsonToCSV = (jsonData, columns) => {
    // Check if jsonData is not an array or is empty
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
        return '';
    }

    // Extract column headers (keys of the JSON objects)
    const headers = Object.keys(jsonData[0]);
    const csvRows = [];

    // Add the headers row
    if (columns) {
        csvRows.push(columns.join(','));
    } else {
        csvRows.push(headers.join(','));
    }

    // Loop over the rows
    for (const row of jsonData) {
        const values = headers.map((header) => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
            return `"${escaped}"`; // Wrap values in double quotes to handle commas and line breaks
        });
        csvRows.push(values.join(','));
    }

    // Combine all rows into a single string with newline characters
    return csvRows.join('\n');
};

export const dayjsInUserTimezone = (...args) => {
    const userTimezone = queryClient.getQueryData(userKeys.detail())?.timezone;

    if (!userTimezone) {
        throw new Error('Failed to get user timezone.');
    }

    return dayjs(...args).tz(userTimezone);
};

export const delayQuery = ({ callback, delay }) => {
    return async (queryContext) => {
        const start = Date.now();
        const result = await callback(queryContext);
        const elapsed = Date.now() - start;

        if (elapsed < delay) {
            await new Promise((resolve) => setTimeout(resolve, delay - elapsed));
        }

        return result;
    };
};
