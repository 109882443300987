import { Control, useController } from 'react-hook-form';
import { default as DatePicker } from '.';

interface DatePickerControllerProps extends React.ComponentProps<typeof DatePicker> {
    control: Control<any>;
}

export const DatePickerController = (props: DatePickerControllerProps) => {
    const { control, name = '', onChange, onBlur, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <DatePicker
            onChange={(value) => {
                onChange?.(value);
                field.onChange(value);
            }}
            value={field.value}
            name={field.name}
            isInvalid={!!fieldState.error}
            errorMessage={fieldState.error?.message}
            {...rest}
        />
    );
};
