import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodeEditPublicationDateModal from '@app/molecules/EpisodeEditPublicationDateModal';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import getScheduleSchema from '@app/organisms/EpisodeEditForm/schemas/scheduleSchema';
import { useOverlayTriggerState } from 'react-stately';
import { useQueryClient } from '@tanstack/react-query';
import useSaveEpisodeFormMutation from '@app/organisms/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import EpisodeEditFormActionsTooltipMessage from '@app/organisms/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { getDirtyValues } from '@/helpers';
import { EPISODE_STATUS, USER_ROLES } from '@/utils/constants';
import userKeys from '@queries/user/userKeys';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import { getChaptersIdsRequiringImageDeletion } from '@app/organisms/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import styled from 'styled-components';

const ScheduleButton = () => {
    const intl = useIntl();
    const { push } = useInternalRouteMapping();
    const queryClient = useQueryClient();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();

    const modalState = useOverlayTriggerState({});

    const episodeBeingEdited = useEpisodeBeingEdited();
    const schema = getScheduleSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const isFreemiumQuotaReached =
        subscription.isFreemium &&
        episodeBeingEdited.show?.userRole === USER_ROLES.OWNER &&
        user.trialEpisodes >= user.maximumTrialEpisodes;
    const isValid = isSchemaValid && hasAudioFile && !isFreemiumQuotaReached;

    const onPress = () => {
        modalState.open();
    };

    const handleScheduleEpisode = async ({ publishedAt }) => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = {
            ...content,
            ...options,
            ...privacy,
            publishedAt,
            state: EPISODE_STATUS.SCHEDULED,
        };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        if (subscription.isFreemium) {
                            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                        }
                        push('experimental.episodes.recap');
                    },
                },
            );
        } catch (error) {
            // Handle error here ?
        }
    };

    return (
        <>
            <ExperimentalTooltip
                isDisabled={isValid}
                content={
                    <EpisodeEditFormActionsTooltipMessage
                        isFreemiumQuotaReached={isFreemiumQuotaReached}
                        hasAudioFile={hasAudioFile}
                        isSchemaValid={isSchemaValid}
                    />
                }
            >
                <ButtonStyled
                    onPress={onPress}
                    isDisabled={!isValid}
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'calendar-clock', style: 'regular' })}
                            color="inherit"
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Planifier" />
                </ButtonStyled>
            </ExperimentalTooltip>
            {modalState.isOpen && (
                <EpisodeEditPublicationDateModal
                    isOpen={modalState.isOpen}
                    onClose={modalState.close}
                    isOnline={false}
                    handleEditPublicationDate={handleScheduleEpisode}
                    isLoading={saveEpisodeFormMutation.isLoading}
                />
            )}
        </>
    );
};

const ButtonStyled = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
`;

export default ScheduleButton;
