import { useEffect } from 'react';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useAINewsletterSuggestionsQuery from '@queries/ai/useAINewsletterSuggestionQuery.hook';
import { useEpisodeAISettings } from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';
import { sendAmplitudeLogEvent } from '@/helpers';

interface EpisodeAINewsletterSuggestionPanelProps {
    setLoadingContent: (content: string) => void;
}

const EpisodeAINewsletterSuggestionPanel = ({
    setLoadingContent,
}: EpisodeAINewsletterSuggestionPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const newsletterSettings = settings.get('newsletter') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const newsletterSuggestionsQuery = useAINewsletterSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('newsletter') || false;
    const queryClient = useQueryClient();
    const { open, isExpanded } = useAccordionItem();
    const { setValue } = useFormContext();

    if (open && !isExpanded && isLoading) {
        open();
    }

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (newsletterSuggestionsQuery.data && newsletterSuggestionsQuery.isSuccess) {
            const formattedString = newsletterSuggestionsQuery.data.suggestion.replace(
                /\n+/g,
                '<p></p>',
            );
            setLoadingContent(formattedString);
            setValue('messageTpl', formattedString, {
                shouldDirty: true,
            });
        }
    }, [newsletterSuggestionsQuery.data, newsletterSuggestionsQuery.isSuccess]);

    // If the query is not fetching and there is no data, suggestions panel should not be displayed
    if (!newsletterSuggestionsQuery.data && !isLoading) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.newsletter() });
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'newsletter',
            custom_prompt: newsletterSettings.customPrompt || '',
            suggestions: newsletterSuggestionsQuery.data?.suggestion,
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={<FormattedMessage defaultMessage="Votre newsletter est prête !" />}
            type="newsletter"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-newsletter-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAINewsletterSuggestionPanel;
