import { FormattedMessage } from 'react-intl';
import Error from '../Error';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '../Link/ExternalLink';

const MaintenanceError = () => {
    return (
        <Error
            title={<FormattedMessage defaultMessage="Nous serons bientôt de retour !" />}
            subtitle={
                <FormattedMessage defaultMessage="Notre petit robot a encore fait des siennes..." />
            }
            message={
                <FormattedMessage defaultMessage="Ausha est actuellement en maintenance. Désolé pour la gêne occasionnée et merci pour votre patience. 🙏" />
            }
            action={
                <ExternalLink
                    variant="button-tertiary"
                    href="https://status.ausha.co/"
                    target="_blank"
                    endIcon={<ArrowIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />}
                >
                    <FormattedMessage defaultMessage="Vérifier le statut" />
                </ExternalLink>
            }
        />
    );
};

const ArrowIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default MaintenanceError;
