import O5H4 from '@public/images/bot.webp';
import Img from '@ui/atoms/Img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as faIcon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@ui/atoms/Button';
import type { UiVariant } from '@/utils/constants';
import styled, { css } from 'styled-components';

const VARIANTS = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ALERT: 'alert',
    ROBOT: 'robot',
    NEUTRAL: 'neutral',
    PRIMARY: 'primary',
};

const DEFAULT_ICONS = {
    [VARIANTS.SUCCESS]: <FontAwesomeIcon icon={faIcon({ name: 'check-circle', style: 'solid' })} />,
    [VARIANTS.INFO]: <FontAwesomeIcon icon={faIcon({ name: 'info-circle', style: 'solid' })} />,
    [VARIANTS.WARNING]: (
        <FontAwesomeIcon icon={faIcon({ name: 'triangle-exclamation', style: 'solid' })} />
    ),
    [VARIANTS.ALERT]: (
        <FontAwesomeIcon icon={faIcon({ name: 'circle-exclamation', style: 'solid' })} />
    ),
    [VARIANTS.ROBOT]: null,
    [VARIANTS.NEUTRAL]: (
        <FontAwesomeIcon icon={faIcon({ name: 'circle-question', style: 'solid' })} />
    ),
    [VARIANTS.PRIMARY]: <FontAwesomeIcon icon={faIcon({ name: 'info-circle', style: 'solid' })} />,
};

export type AlertVariant = UiVariant | 'robot';

export interface AlertProps {
    children: React.ReactNode;
    variant: AlertVariant;
    icon?: React.ReactNode;
    defaultIcon?: boolean;
    description?: React.ReactNode;
    action?: React.ReactNode;
    closable?: boolean;
    onClose?: () => void;
    [x: string]: any;
}

const Alert = ({
    children,
    variant,
    icon,
    defaultIcon,
    description,
    action,
    closable,
    onClose,
    ...props
}: React.PropsWithChildren<AlertProps>) => {
    // @ts-ignore
    const iconIsFA = icon?.type?.displayName === 'FontAwesomeIcon';

    return (
        <AlertContainer variant={variant} {...props}>
            <IconWrapper variant={variant} iconIsFA={iconIsFA}>
                {!icon && defaultIcon && DEFAULT_ICONS[variant]}
                {variant === VARIANTS.ROBOT ? <Img src={O5H4} /> : icon}
            </IconWrapper>
            {children && (
                <ContentWrapper isRobot={variant === VARIANTS.ROBOT}>
                    <div>{children}</div>
                    {description}
                </ContentWrapper>
            )}
            {action && action}
            {closable && (
                <CloseWrapper isRobot={variant === VARIANTS.ROBOT}>
                    {/* @ts-ignore */}
                    <CloseButton variant="ghost" color="--neutral500" onPress={onClose}>
                        <CloseButtonIcon icon={faIcon({ name: 'xmark', style: 'solid' })} />
                    </CloseButton>
                </CloseWrapper>
            )}
        </AlertContainer>
    );
};

const AlertContainer = styled.div<{ variant: AlertVariant }>`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: var(--r-m);
    white-space: normal;
    background-color: ${(p) =>
        p.variant === 'neutral' ? `var(--neutral100)` : `var(--${p.variant}50)}`};
    padding-inline: 1rem;
    padding-block: 0.75rem;
    gap: 0.75rem;

    ${(p) =>
        p.variant === VARIANTS.ROBOT &&
        css`
            padding: 0;
            background-color: var(--primary50);
            column-gap: 0.5rem;
        `}
`;
const IconWrapper = styled.div<{ variant: AlertVariant; iconIsFA: boolean }>`
    ${(props) =>
        props.variant === VARIANTS.ROBOT
            ? css`
                  align-self: stretch;
                  width: 48px;
                  overflow: hidden;
                  position: relative;
                  border-bottom-left-radius: var(--r-m);

                  & > img {
                      height: 82px;
                      width: auto;
                      position: absolute;
                      top: 0.5rem;
                      left: -0.5rem;
                  }
              `
            : css`
                  color: ${`var(--${props.variant}500)`};
                  line-height: 0;
                  transform: translateY(0.125rem);

                  ${!props.iconIsFA &&
                  css`
                      & > svg {
                          fill: ${`var(--${props.variant}500)`};
                          font-size: 1rem;
                          align-self: center;
                      }
                  `}
              `}
`;
const ContentWrapper = styled.div<{ isRobot: boolean }>`
    display: flex;
    flex-direction: column;
    row-gap: 0.125rem;
    flex: 1;

    ${(props) =>
        props.isRobot &&
        css`
            padding-block: 0.75rem;
            padding-right: 1rem;
        `}
`;
const CloseWrapper = styled.div<{ isRobot: boolean }>`
    ${(props) =>
        props.isRobot &&
        css`
            padding-right: 0.75rem;
            padding-top: 0.75rem;
        `}
`;
const CloseButton = styled(Button)`
    background-color: transparent;
    padding: 0.25rem;
    height: fit-content;
`;
const CloseButtonIcon = styled(FontAwesomeIcon)`
    font-size: 1.1rem;
`;

export default Alert;
