import styled, { keyframes } from 'styled-components';
import Text from '../ui/atoms/Text';
import React from 'react';
import NebulaSparklesIcon from '../ui/NebulaSparklesIcon';

const AnimatedLoading = () => (
    <DotsContainer viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
        <Dot d="m0 256c0-30.721 25.279-56 56-56s56 25.279 56 56-25.279 56-56 56-56-25.279-56-56z" />
        <Dot d="m160 256c0-30.721 25.279-56 56-56s56 25.279 56 56-25.279 56-56 56-56-25.279-56-56z" />
        <Dot d="m376 200c30.721 0 56 25.279 56 56s-25.279 56-56 56-56-25.279-56-56 25.279-56 56-56z" />
    </DotsContainer>
);

interface EpisodeAILoaderProps {
    'data-testid': string;
    children?: React.ReactNode;
    className?: string;
}

const EpisodeAILoader = ({
    'data-testid': dataTestId,
    children,
    className,
}: EpisodeAILoaderProps) => {
    return (
        <Panel data-testid={dataTestId} className={className}>
            <Sparkles />
            <PanelInner>
                <Text color="--primary-magic" fontWeight="--fw-semibold">
                    {children}
                </Text>
                <AnimatedLoading />
            </PanelInner>
        </Panel>
    );
};

const waveAnimation = keyframes`
  0%, 50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10%);
  }
`;
const Panel = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 0.5rem;
    position: relative;
    margin-top: -0.5rem;
    margin-inline: 0.5rem;
    padding: 0.75rem;
    background: var(--gradient-aurora);
    border-radius: var(--r-m);
    box-shadow: var(--s-aurora);
    flex-direction: row;
    width: fit-content;
`;
const PanelInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const DotsContainer = styled.svg`
    width: 0.75rem;
    height: 0.75rem;
`;
const Dot = styled.path`
    animation: ${waveAnimation} 0.5s ease-in-out alternate infinite;
    fill: var(--primary-magic);

    &:nth-child(1) {
        animation-delay: 0s;
    }

    &:nth-child(2) {
        animation-delay: 0.1s;
    }

    &:nth-child(3) {
        animation-delay: 0.2s;
    }
`;
const Sparkles = styled(NebulaSparklesIcon)`
    width: 1rem;
    height: 1rem;
`;

export default EpisodeAILoader;
