import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/context/EpisodeEditContext';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import EpisodeEditResumeAudioFileInput from '../EpisodeEditResumeAudioFileInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import useEpisodeAudioFileSchema from '@/utils/hooks/schemas/useEpisodeAudioFileSchema.hook';
import { debounce } from 'lodash';

const EpisodeEditResumeAudioFile = ({ audioFile }) => {
    const intl = useIntl();
    const { episodeId } = useParams();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [_, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const schema = useEpisodeAudioFileSchema();

    const { control, watch, handleSubmit, setError } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            audioFile: audioFile ?? null,
        },
    });

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = ({ audioFile }) => {
        episodeEditAudioFileMutation.mutate(
            { episodeId: episodeId, audioFile },
            {
                onSuccess: () => {
                    localStorage.setItem(`episode-${episodeId}-audio-file-uploaded`, 'true');
                    debounce(() => setEpisodeEditAudioFile(null), 300)();
                },
                onError: () => {
                    setEpisodeEditAudioFile(null);
                    setError('root.serverError', {
                        type: '500',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    });
                },
            },
        );
        // Keep audioFile in a state to display audioFile data in
        // episode form during audioFile upload
        setEpisodeEditAudioFile(audioFile);
    };

    return (
        <Controller
            control={control}
            name="audioFile"
            render={({ field: { value, ...field }, formState: { errors } }) => (
                <EpisodeEditResumeAudioFileInput {...field} error={errors?.root?.serverError} />
            )}
        />
    );
};

export default EpisodeEditResumeAudioFile;
