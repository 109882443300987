import { useState, useEffect } from 'react';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import { ProgressBar, Label } from 'react-aria-components';
import { formatDuration } from '@/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { mediaQueries } from '@/styles/theme';
import styled from 'styled-components';

const isDesktop = window.matchMedia(
    mediaQueries.mediaQueries.desktopLargeAndUp.split('@media ')[1],
).matches;
const playerWidth = isDesktop ? 500 : 150;
const durationOptions = { h: '', mn: '', sec: '', join: ':', full: true };
const audio = new Audio();

const AushaHeaderPlayer = () => {
    const [ready, setReady] = useState(false);
    const [progress, setProgress] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [audioProgress, setAudioProgress] = useState(0);
    const { currentlyPlaying, setCurrentlyPlaying, audioIsPlaying, audioIsPaused, play, pause } =
        useAudioPlayerContext();

    const onReady = () => {
        setReady(true);
        setAudioDuration(audio.duration);
    };

    const onUpdate = () => {
        const currentTime = audio?.currentTime || 0;
        const duration = audio?.duration || 0;
        setAudioProgress(currentTime);
        const currentProgress = duration > 0 ? (currentTime / duration) * 100 : 0;
        setProgress(Math.min(100, Math.max(0, currentProgress)));
    };

    const onEnded = () => {
        !audio.paused && audio.pause();
        setProgress(0);
        pause();
        setCurrentlyPlaying(null);
    };

    useEffect(() => {
        if (!currentlyPlaying) return;
        audio.addEventListener('canplaythrough', onReady);
        audio.addEventListener('timeupdate', onUpdate);
        audio.addEventListener('ended', onEnded);

        if (audio.src !== currentlyPlaying.url) {
            !audio.paused && audio.pause();
            audio.src = currentlyPlaying.url;
        }

        audioIsPlaying ? audio.play() : audio.pause();

        return () => {
            audio.pause();
            audio.removeEventListener('canplaythrough', onReady);
            audio.removeEventListener('timeupdate', onUpdate);
            audio.removeEventListener('ended', onEnded);
        };
    }, [currentlyPlaying, audioIsPlaying, audioIsPaused]);

    const onProgressClick = ({ nativeEvent }: any) => {
        const percent = nativeEvent.offsetX / playerWidth;
        audio.currentTime = percent * audio.duration;
    };

    if (!currentlyPlaying || !audio || !ready) return null;
    return (
        <PlayerWrapper>
            {audioIsPlaying ? (
                <Button
                    onPress={() => {
                        pause();
                    }}
                >
                    <FontAwesomeIcon icon={icon({ name: 'pause', style: 'solid' })} />
                </Button>
            ) : (
                <Button
                    onPress={() => {
                        play(currentlyPlaying);
                    }}
                >
                    <FontAwesomeIcon icon={icon({ name: 'play', style: 'solid' })} />
                </Button>
            )}
            <ProgressBar value={progress}>
                {({ percentage }) => (
                    <ProgressInnerWrapper $playerWidth={playerWidth}>
                        <LabelAndValue>
                            <StyledLabel>{currentlyPlaying.name}</StyledLabel>
                            <Value>
                                <span>{formatDuration(audioProgress, durationOptions)}</span>
                                <DurationWrapper>
                                    <span>/</span>
                                    <span>{formatDuration(audioDuration, durationOptions)}</span>
                                </DurationWrapper>
                            </Value>
                        </LabelAndValue>
                        <Bar className="bar" onClick={onProgressClick}>
                            <Fill style={{ width: percentage + '%' }} />
                        </Bar>
                    </ProgressInnerWrapper>
                )}
            </ProgressBar>
        </PlayerWrapper>
    );
};

const PlayerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const ProgressInnerWrapper = styled.div<{ $playerWidth: number }>`
    display: flex;
    flex-direction: column;
    width: ${({ $playerWidth }) => $playerWidth}px;
`;
const LabelAndValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const StyledLabel = styled(Label)`
    color: var(--white);
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-s);
`;
const Value = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--white);
    font-size: var(--fs-body-s);
`;
const DurationWrapper = styled.div`
    display: inline-flex;
    gap: 0.25rem;

    @media (max-width: 1000px) {
        display: none;
    }
`;
const Bar = styled.div`
    transition-duration: 0.2;
    background-color: var(--white100);
    width: 100%;
    height: 0.25rem;
    border-radius: var(--r-xs);
    cursor: pointer;

    &:hover {
        transition-duration: 0.2;
        height: 0.75rem;
    }
`;
const Fill = styled.div`
    height: 100%;
    border-radius: var(--r-xs);
    background-color: var(--white);
`;

export default AushaHeaderPlayer;
