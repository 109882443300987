import { css } from 'styled-components';
import {
    buttonDisabledStyles,
    buttonFocusVisibleStyles,
    buttonFontStyles,
    buttonHoveredStyles,
    buttonLayoutStyles,
    buttonLoadingStyles,
    buttonPressedStyles,
    buttonResetStyles,
    buttonTransitionStyles,
} from '../base';

export const primaryButtonStyles = css`
    ${buttonResetStyles}
    ${buttonFontStyles}
    ${buttonTransitionStyles}
    ${buttonLoadingStyles}
    ${buttonDisabledStyles}
    ${buttonHoveredStyles}
    ${buttonPressedStyles}
    ${buttonFocusVisibleStyles}
    ${buttonLayoutStyles}

    color: var(--white);
    background-color: var(--primary);
    box-shadow: 0px 4px 16px 0px rgba(117, 28, 191, 0.3);
    &:hover {
        background-color: var(--primary400);
    }
    &[data-hovered] {
        background-color: var(--primary400);
    }
`;
