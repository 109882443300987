import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { secondsToDuration } from '@/helpers';
import Text from '@ui/atoms/Text';
import { useSwitch } from '@hooks/useSwitch';
import IconButton from '@/components/ui/atoms/IconButton';
import IconDelete from '@/components/ui/icons/IconDelete';

const EpisodeChaptersSelectorListItem = ({ chapter, isSelected = false, onSelect, onDelete }) => {
    const [isHovered, onHover, onBlur] = useSwitch(false);

    return (
        <ChapterWrapper
            onClick={() => onSelect(chapter.id)}
            onMouseEnter={onHover}
            onMouseLeave={onBlur}
            isSelected={isSelected}
        >
            <Left>
                {chapter.number && (
                    <Text
                        color={isSelected ? '--primary' : '--neutral500'}
                        fontWeight={isSelected ? '--fw-bold' : '--fw-normal'}
                    >{`#${chapter.number}`}</Text>
                )}
                <Text
                    color={isSelected ? '--primary' : '--black'}
                    fontWeight={isSelected ? '--fw-bold' : '--fw-normal'}
                >
                    {chapter.title}
                </Text>
            </Left>
            {isHovered ? (
                <DeleteButton
                    variant="ghost"
                    size="small"
                    color="--primary"
                    icon={<IconDelete />}
                    onClick={(event) => {
                        event.stopPropagation();
                        onDelete(chapter.id);
                    }}
                />
            ) : (
                <Text color={isSelected ? '--primary' : '--neutral500'}>
                    {secondsToDuration(chapter.startTime)}
                </Text>
            )}
        </ChapterWrapper>
    );
};

const ChapterWrapper = styled(motion.li)`
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: var(--r-xs);
    border: 1px solid ${(props) => (props.isSelected ? 'var(--primary)' : 'var(--neutral200)')};
    min-height: 40px;
    cursor: pointer;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const DeleteButton = styled(IconButton)`
    padding: 0;
    flex-shrink: 0;

    & > * {
        width: 1rem;
        height: auto;
    }
`;

EpisodeChaptersSelectorListItem.propTypes = {
    chapter: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
};

EpisodeChaptersSelectorListItem.defaultProps = {
    onSelect: () => {},
    onDelete: () => {},
};

export default EpisodeChaptersSelectorListItem;
