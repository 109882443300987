import useHasAccessToEpisodeAI from '@/utils/hooks/useHasAccessToEpisodeAI.hook';
import { useParams } from 'react-router';
import styled from 'styled-components';
import useEpisodeTranscriptStatusQuery from '@/queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import { EPISODE_TRANSCRIPT_STATUS } from '@/utils/constants';
import EpisodeAIUnauthorizedState from '@/components/EpisodeAI/EpisodeAIUnauthorizedState';
import EpisodeAITranscriptionMissingState from '@/components/EpisodeAI/EpisodeAITranscriptionMissingState';
import EpisodeAIPanel from '@/components/EpisodeAI/EpisodeAIPanel';
import useAISummaryQuery from '@/queries/ai/useAISummaryQuery.hook';
import EpisodeAISummaryLoading from '@/components/EpisodeAI/EpisodeAISummaryLoading';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';

const AITab = () => {
    const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();
    const episode = useEpisodeBeingEdited();
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });
    const { data: transcriptionStatus } = useEpisodeTranscriptStatusQuery(episodeId);
    const hasTranscription = transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE;
    const summary = useAISummaryQuery({
        episodeId,
        options: {
            enabled:
                !!episodeId &&
                (hasAccessToEpisodeAI || episode?.aiFreeTrialEnabled) &&
                hasTranscription,
        },
    });

    if (!hasAccessToEpisodeAI && !episode?.aiFreeTrialEnabled) {
        return (
            <Wrapper data-testid="episode-form-ai-tab-content">
                <EpisodeAIUnauthorizedState testId="unauthorized-tab" />
            </Wrapper>
        );
    }

    if (!hasTranscription) {
        return (
            <Wrapper data-testid="episode-form-ai-tab-content">
                <EpisodeAITranscriptionMissingState testId="missing-transcription-tab" />
            </Wrapper>
        );
    }

    if (summary.isLoading || summary.isFetching) {
        return <EpisodeAISummaryLoading dataTestId="episode-ai-tab-summary-loader" />;
    }

    return (
        <Wrapper data-testid="episode-form-ai-tab-content">
            <EpisodeAIPanel generateButtonDataTestId="episode-ai-tab-generate-button" />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export default AITab;
