import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { InputTextController } from '@ui/atoms/InputText';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';

const HelpLink = styled.a`
    color: var(--white);
    text-decoration: underline;
`;

const EpisodeEditFormOptionsYoutubeTitleField = () => {
    const intl = useIntl();
    const { control, watch, setValue, formState } = useFormContext();

    const { dirtyFields } = formState;
    const episodeTitle = watch('content.name');
    const videoTitle = watch('options.youtubeVideoTitle');

    useEffect(() => {
        if (videoTitle || dirtyFields?.options?.youtubeVideoTitle === true) return;
        setValue('options.youtubeVideoTitle', episodeTitle);
    }, [episodeTitle, dirtyFields]);

    return (
        <Stack>
            <InputTextController
                name="options.youtubeVideoTitle"
                control={control}
                label={<FormattedMessage defaultMessage="Titre de la vidéo YouTube" />}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Le titre de votre vidéo youtube',
                })}
                isRequired
                maxLength={100}
                tooltip={
                    <EpisodeEditTooltip>
                        <FormattedMessage
                            defaultMessage="Un titre accrocheur peut vous permettre de capter l'attention des internautes de YouTube. Nous vous conseillons d'inclure des mots clés que les internautes sont susceptibles d'utiliser pour rechercher des contenus comme les vôtres. <link>En savoir plus</link>"
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <HelpLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://www.ausha.co/fr/blog/pourquoi-et-comment-lancer-un-podcast-sur-youtube/',
                                        })}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {chunks}
                                    </HelpLink>
                                ),
                            }}
                        />
                    </EpisodeEditTooltip>
                }
                data-testid="new-episode-youtube-title-input"
            />
        </Stack>
    );
};

export default EpisodeEditFormOptionsYoutubeTitleField;
