import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListBox, ListBoxItem, Selection } from 'react-aria-components';
import styled from 'styled-components';

interface TranscriptionPlayerAudioRateMenuProps {
    value: number;
    onChange?: (value: number) => void;
    onClose: () => void;
}

const TranscriptionPlayerAudioRateMenu = ({
    value,
    onChange,
    onClose,
}: TranscriptionPlayerAudioRateMenuProps) => {
    const handleAudioRateChange = (selection: Selection) => {
        if (selection === 'all') return null;
        const rate = selection.values().next().value;
        onChange?.(parseFloat(rate));
        onClose();
    };

    return (
        <List
            onSelectionChange={handleAudioRateChange}
            aria-label="Audio rate"
            selectionMode="single"
            selectedKeys={[String(value)]}
            disallowEmptySelection={true}
        >
            <ListItem id="2">
                x2
                <ListItemIcon icon={icon({ name: 'check', style: 'solid' })} />
            </ListItem>
            <ListItem id="1.5">
                x1.5
                <ListItemIcon icon={icon({ name: 'check', style: 'solid' })} />
            </ListItem>
            <ListItem id="1">
                x1
                <ListItemIcon icon={icon({ name: 'check', style: 'solid' })} />
            </ListItem>
            <ListItem id="0.5">
                x0.5
                <ListItemIcon icon={icon({ name: 'check', style: 'solid' })} />
            </ListItem>
        </List>
    );
};

const List = styled(ListBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
    width: 4.75rem;
    padding: 0.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
`;
const ListItemIcon = styled(FontAwesomeIcon)`
    display: none;
    color: var(--primary500);
    width: 0.75rem;
    height: 0.75rem;
`;

const ListItem = styled(ListBoxItem)`
    display: flex;
    padding: 0.25rem 0.5rem;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    align-self: stretch;
    border-radius: var(--r-s);
    cursor: pointer;

    &[data-selected='true'] {
        background-color: var(--primary50);

        & > ${ListItemIcon} {
            display: block;
        }
    }

    &[data-hovered='true']:not([data-selected='true']) {
        background-color: var(--neutral50);
    }
`;

export default TranscriptionPlayerAudioRateMenu;
