import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ACTIONS_CTA, ICON_LIST_CTA, LINKS_NAMES } from './CTAAccessAllowed';
import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';

import { Header, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import styled from 'styled-components';

interface CTAMenuDropDownProps {
    dispatch: (action: any) => void;
    ctaList: any[];
}

const CTAMenuDropDown = ({ dispatch, ctaList }: CTAMenuDropDownProps) => {
    const { showId } = useParams<{ showId: string }>();
    const callToActionButtons = useCallToActionButtonsQuery({ showId });

    if (callToActionButtons.isLoading || callToActionButtons.isError) {
        return null;
    }

    const currentCtaTypes = callToActionButtons.data.map((cta) => cta.type);
    const ALL_CTAS_AVAILABLE = [...ctaList, ...callToActionButtons.data];
    const defaultFields = [
        LINKS_NAMES.PAGE_PODCAST,
        LINKS_NAMES.SMARTLINK,
        LINKS_NAMES.NEWSLETTER,
        LINKS_NAMES.CROWDFUNDING,
    ];

    return (
        <MenuTrigger>
            <Button
                aria-label="Menu"
                startIcon={<FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />}
            >
                <FormattedMessage defaultMessage="Créer un bouton personnalisé" />
            </Button>
            <StyledPopover>
                <StyledMenu>
                    <StyledMenuItem>
                        <StyledHeader>
                            <FormattedMessage defaultMessage="Accès direct" />
                        </StyledHeader>
                    </StyledMenuItem>
                    {ALL_CTAS_AVAILABLE.map(
                        (item) =>
                            item.url &&
                            !currentCtaTypes.includes(item.type) && (
                                <StyledMenuItem
                                    onAction={() => {
                                        dispatch({
                                            type: defaultFields.includes(item.type)
                                                ? ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA
                                                : ACTIONS_CTA.OPEN_EDIT_MODAL_CTA,
                                            payload: {
                                                closeDropDownCTA: () => {},
                                                item: { ...item },
                                            },
                                        });
                                    }}
                                >
                                    <MenuItemInnerWrapper>
                                        <MenuItemTitleWrapper>
                                            {ICON_LIST_CTA[item.type]}
                                            <MenuItemTitle>{item.text}</MenuItemTitle>
                                        </MenuItemTitleWrapper>
                                        <Url>{item.url}</Url>
                                    </MenuItemInnerWrapper>
                                </StyledMenuItem>
                            ),
                    )}
                    <StyledMenuItem
                        onAction={() => {
                            dispatch({
                                type: ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA,
                                payload: { closeDropDownCTA: () => {}, item: { type: 'custom' } },
                            });
                        }}
                    >
                        <MenuItemInnerWrapper>
                            <NewButtonButton
                                variant="ghost"
                                size="small"
                                onPress={() => {
                                    dispatch({
                                        type: ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA,
                                        payload: {
                                            closeDropDownCTA: () => {},
                                            item: { type: 'custom' },
                                        },
                                    });
                                }}
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'plus', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Nouveau bouton" />
                            </NewButtonButton>
                        </MenuItemInnerWrapper>
                    </StyledMenuItem>
                </StyledMenu>
            </StyledPopover>
        </MenuTrigger>
    );
};

const StyledPopover = styled(Popover)`
    background-color: var(--white);
    border-radius: var(--r-xs);
    box-shadow: var(--s-light);
`;
const StyledMenu = styled(Menu)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const StyledMenuItem = styled(MenuItem)`
    cursor: pointer;
`;
const MenuItemInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const MenuItemTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-body-s);
`;
const MenuItemTitle = styled.span`
    font-size: var(--fs-body-m);
`;
const StyledHeader = styled(Header)`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
`;
const Url = styled.span`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    padding-left: 1.25rem;
`;
const NewButtonButton = styled(Button)`
    width: max-content;
`;

export default CTAMenuDropDown;
