import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIPanelField from './EpisodeAIPanelField';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EpisodeAIOverwriteWarningModal from '../EpisodeAIOverwriteWarningModal';
import { useParams } from 'react-router';
import useAIChaptersSuggestionsQuery from '@queries/ai/useAIChaptersSuggestionsQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import { useEpisodeBeingEdited } from '@/components/app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import useAISuggestionsError from '../utils/useAISuggestionsError.hook';

interface EpisodeAIChaptersFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAIChaptersField = ({ generateButtonDataTestId }: EpisodeAIChaptersFieldProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const chaptersSuggestionsQuery = useAIChaptersSuggestionsQuery({
        episodeId,
        delay: 2000,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { watch } = useFormContext();
    const chapters = watch('chapters');
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-chapters`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('chapters') || false;
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];
    const fileDuration = uploadedAudioFile?.duration;
    const isDurationTooShort = fileDuration && fileDuration < 300;

    useAISuggestionsError({
        isError: chaptersSuggestionsQuery.isError,
        episodeId,
        type: 'chapters',
    });

    // Once the chapters suggestions are fetched, set the generated flag to true
    useEffect(() => {
        if (chaptersSuggestionsQuery.data && !chaptersSuggestionsQuery.isFetching) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-chapters`, 'true');
            setIsGenerated(true);
            dispatch({
                type: EPISODE_AI_LOADING_ACTIONS.STOP,
                payload: 'chapters',
            });
        }
    }, [chaptersSuggestionsQuery.data, chaptersSuggestionsQuery.isFetching]);

    const handleOpenModal = () => {
        if (chapters && chapters.length > 0) {
            setIsModalOpen(true);
            return;
        }
        handleGenerate();
    };

    const handleGenerate = () => {
        // No regeneration possible for chapters
        if (isGenerated) return;
        setIsModalOpen(false);
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'chapters',
        });
        chaptersSuggestionsQuery.refetch();
    };

    return (
        <>
            <EpisodeAIPanelField
                data-testid={generateButtonDataTestId}
                icon={<Icon icon={icon({ name: 'bookmark', style: 'solid' })} />}
                title={<FormattedMessage defaultMessage="Chapitres" />}
                description={
                    <FormattedMessage defaultMessage="Des sections claires pour faciliter la navigation de votre épisode." />
                }
                onAction={handleOpenModal}
                isLoading={isLoading}
                isGenerated={isGenerated && !isLoading}
                isDisabled={isDurationTooShort || isGenerated}
                type="chapters"
                tooltip={
                    isDurationTooShort ? (
                        <FormattedMessage defaultMessage="La génération des chapitres n'est disponible que pour les épisodes de plus de 5min. " />
                    ) : (
                        <FormattedMessage defaultMessage="La personnalisation des prompts des chapitres n'est pas disponible." />
                    )
                }
            />
            <EpisodeAIOverwriteWarningModal
                isOpen={isModalOpen}
                onOpenChange={setIsModalOpen}
                onAction={handleGenerate}
                actionTestId="episode-ai-chapters-overwrite-modal-button"
                message={
                    <FormattedMessage defaultMessage="La génération AI écrasera les chapitres actuels" />
                }
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAIChaptersField;
