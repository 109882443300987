import { loginSharingProvider, fetchSharingProvider, fetchYoutubeQuota } from '@/api';
import { action, computed, runInAction } from 'mobx';
import {
    fetchShowTemplate,
    updateShowTemplate,
    unlinkSharing,
    uploadMessageImage,
    deleteUploadedMessageImage,
} from '@/api';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import SharingAccountModel from '../models/SharingAccountModel';
import FacebookPageModel from '../models/FacebookPageModel';
import SharingTemplateModel from '../models/SharingTemplateModel';
import { updatePodcastNewsletterTemplate } from '@/api';
import YoutubeAccountModel from '../models/YoutubeAccountModel';
import SoundcloudAccountModel from '../models/SoundcloudAccountModel';
import InstagramAccountModel from '../models/InstagramAccountModel';
import LinkedInAccountModel from '../models/LinkedInAccountModel';
import {
    FACEBOOK,
    INSTAGRAM,
    LINKEDIN,
    SOCIAL_NETWORK_NAME,
    UI_VARIANTS,
} from '../utils/constants';
import YoutubeQuotaModel from '../models/YoutubeQuotaModel';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';

class AutoSharingStore extends Store {
    static observables = {
        // PROVIDERS CONNECTED
        facebookAccount: null,
        twitterAccount: null,
        linkedinAccount: null,
        youtubeAccount: null,
        soundcloudAccount: null,
        instagramAccount: null,

        // SPECIFIC PAYLOAD
        facebookPages: [],
        allInstagramAccounts: [],
        allLinkedInAccounts: [],

        // SHOWS TEMPLATE
        facebookShowTemplate: null,
        twitterShowTemplate: null,
        linkedinShowTemplate: null,
        instagramShowTemplate: null,
        newsletterShowTemplate: null,

        // PODCASTS TEMPLATE
        facebookPodcastTemplate: null,
        twitterPodcastTemplate: null,
        linkedinPodcastTemplate: null,
        instagramPodcastTemplate: null,
        newsletterPodcastTemplate: null,

        posts: [],
        pagination: {},

        youtubeQuota: {},

        postsByEpisode: {},
    };

    @computed
    get facebookPagesSource() {
        return this.facebookPages.map((p) => ({
            label: p.name,
            value: p.id,
        }));
    }

    // API
    @apiCall
    async loginWith(formData, showId) {
        const { data } = await loginSharingProvider(showId, formData);

        switch (formData.provider) {
            case 'instagram':
                if (data.axios.data.length > 1) {
                    await runInAction(() => {
                        this.allInstagramAccounts = data.axios.data.map(
                            (account) => new InstagramAccountModel(this, account),
                        );
                    });
                } else {
                    await updateShowTemplate(showId, formData.provider, {
                        params: { user_id: data[0].id },
                    });
                    runInAction(() => {
                        this.updateAccount(formData.provider, { account: data[0] });
                    });
                }
                break;
            case 'linkedin':
                if (data.axios.data.length > 1) {
                    await runInAction(() => {
                        this.allLinkedInAccounts = data.axios.data.map(
                            (account) => new LinkedInAccountModel(this, account),
                        );
                    });
                } else {
                    await updateShowTemplate(showId, formData.provider, {
                        params: { account_id: data[0].id },
                    });
                    runInAction(() => {
                        this.updateAccount(formData.provider, { account: data[0] });
                    });
                }
                break;
            default:
                this.updateAccount(formData.provider, data);
        }
        if (this[`${formData.provider}Account`])
            modalToastQueue.add(
                <FormattedMessage
                    defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                    values={{ platform: SOCIAL_NETWORK_NAME[formData.provider] }}
                />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
        else
            modalToastQueue.add(
                <FormattedMessage
                    defaultMessage="Oups, vous n'avez pas pu connecter votre compte {provider}"
                    values={{ provider: SOCIAL_NETWORK_NAME[formData.provider] }}
                />,
                {
                    variant: UI_VARIANTS.ALERT,
                    timeout: 5000,
                },
            );
    }

    @apiFetch
    async fetchSharingProvider(showId, provider) {
        try {
            const data = await fetchSharingProvider(showId, provider);
            this.updateAccount(provider, { ...data });
        } catch (e) {
            this[`${provider}Account`] = null;
        }
    }

    @apiFetch
    async fetchShowTemplate(showId, provider) {
        try {
            const { data } = await fetchShowTemplate(showId, provider);
            runInAction(() => {
                this[`${provider}ShowTemplate`] = new SharingTemplateModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this[`${provider}ShowTemplate`] = new SharingTemplateModel(this);
            });
        }
    }

    @apiFetch
    async fetchYoutubeQuota() {
        try {
            const { data } = await fetchYoutubeQuota();
            runInAction(() => {
                this.youtubeQuota = new YoutubeQuotaModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this.youtubeQuota = new YoutubeQuotaModel(this);
            });
        }
    }

    @apiCall
    async updateShowTemplate(template, provider, showId, formData) {
        let providerTemplate;
        switch (provider) {
            case FACEBOOK:
                providerTemplate = { params: { page_id: formData.page_id } } || {};
                break;
            case INSTAGRAM:
                providerTemplate =
                    { params: { user_id: formData.id || this.instagramAccount.id } } || {};
                break;
            case LINKEDIN:
                providerTemplate =
                    { params: { account_id: formData.account_id || this.linkedinAccount.id } } ||
                    {};
                break;
            default:
                providerTemplate = { params: { activated: formData.activated } };
        }
        const { data } = await updateShowTemplate(
            showId,
            provider,
            Object.assign({ message_tpl: formData.message_tpl }, providerTemplate),
        );
        template.updateData(data);
    }

    @apiCall
    async updatePodcastNewsletterTemplate(template, podcastId, formData) {
        const providerTemplate = {
            params: { activated: formData.activated, title: formData.title },
        };
        const { data } = await updatePodcastNewsletterTemplate(
            podcastId,
            Object.assign(
                {
                    message_tpl: formData.message_tpl,
                    type: formData.type,
                    clip_format_id: formData.clip_format_id,
                    publish_at: formData.publish_at,
                    page_id: template.page_id,
                    state: formData.state,
                },
                providerTemplate,
            ),
        );
        template.updateData(data);
    }

    @apiCall
    async unlinkSharing(showId, provider) {
        await unlinkSharing(showId, provider);
        runInAction(() => {
            this[`${provider}Account`] = null;
        });
    }

    @apiCall
    async uploadMessageImage(messageId, file) {
        return uploadMessageImage(messageId, file);
    }

    @apiCall
    async deleteUploadedMessageImage(messageId) {
        await deleteUploadedMessageImage(messageId);
    }

    @action
    updateAccount(provider, { account, pages }) {
        let providerAccount;
        switch (provider) {
            case 'youtube':
                providerAccount = new YoutubeAccountModel(this, account);
                break;
            case 'soundcloud':
                providerAccount = new SoundcloudAccountModel(this, account);
                break;
            case 'instagram':
                providerAccount = new InstagramAccountModel(this, account);
                break;
            case 'linkedin':
                providerAccount = new LinkedInAccountModel(this, account);
                break;
            default:
                providerAccount = new SharingAccountModel(this, account);
        }
        this[`${provider}Account`] = providerAccount;

        if (provider === 'facebook') {
            this.facebookPages = pages.map((page) => new FacebookPageModel(this, page));
        }
    }
}

export default AutoSharingStore;
