import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import Stack from '@/components/ui/layout/Stack';
import EpisodeAINewsletterSuggestionPanel from '@/components/EpisodeAI/EpisodeAINewsletterSuggestionPanel';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';

const EpisodeEditFormNewsletterMessageField = () => {
    const { control } = useFormContext();
    const intl = useIntl();
    const [loadingContent, setLoadingContent] = useState('');
    const { isExpanded } = useAccordionItem();
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('newsletter') || false;

    // Stop wysiwyg loading animation when the accordion is closed
    useEffect(() => {
        if (!isExpanded) {
            handleStopLoading();
        }
    }, [isExpanded]);

    const handleStopLoading = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.STOP,
            payload: 'newsletter',
        });
        setLoadingContent('');
    };

    return (
        <Stack>
            <InputRichTextController
                name="messageTpl"
                control={control}
                label={<FormattedMessage defaultMessage="Contenu de la newsletter" />}
                charactersLimit={4000}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Saisissez le contenu de votre newsletter',
                })}
                data-testid="new-episode-newsletter-message-input"
                isLoading={isLoading}
                onLoadingEnd={handleStopLoading}
                loadingContent={loadingContent}
            />
            <EpisodeAINewsletterSuggestionPanel setLoadingContent={setLoadingContent} />
        </Stack>
    );
};

export default EpisodeEditFormNewsletterMessageField;
