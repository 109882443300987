import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { extractCssVariable, resolveColor } from '@/utils/cssVariables';
import { WaveForm, WaveSurfer } from 'wavesurfer-react';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min';

import { formatTime } from '@/helpers';
import { useResponsive } from '@/utils/hooks/useResponsive';

const TranscriptionWaveform = ({
    audioUrl,
    waveformDataUrl,
    onDurationChange,
    onCurrentTimeChange,
    cursorPosition,
    isPlaying,
    audioRate = 1,
}) => {
    const { isDesktop } = useResponsive();
    const WAVE_FORM_HEIGHT = isDesktop ? 64 : 32;
    const wavesurferRef = useRef();
    const plugins = useMemo(() => {
        return [
            {
                plugin: CursorPlugin,
                options: {
                    showTime: true,
                    container: wavesurferRef,
                    opacity: 1,
                    color: resolveColor('--primary200'),
                    customShowTimeStyle: {
                        background: resolveColor('--primary100'),
                        color: resolveColor('--primary'),
                        padding: '0 4px',
                        margin: '4px',
                        top: 0,
                        height: '18px',
                        'border-radius': extractCssVariable('--r-xs'),
                        'font-size': extractCssVariable('--fs-body-s'),
                        'text-align': 'center',
                    },
                    formatTimeCallback: (currentTime) => formatTime(currentTime),
                },
            },
        ];
    }, []);

    const normalize = (dataArray) => {
        const maxValue = dataArray.reduce((value1, value2) => {
            return Math.max(value1, value2);
        }, 0);

        return dataArray.map((value) => value / maxValue).map((value) => value * 0.8);
    };

    const handleWSMount = useCallback(async (waveSurfer) => {
        wavesurferRef.current = waveSurfer;

        if (!wavesurferRef.current) {
            return;
        }

        const waveformDataResponse = await fetch(waveformDataUrl);

        if (!waveformDataResponse.ok) {
            throw new Error(`Error while fetching waveform data`);
        }

        const waveformDataBuffer = await waveformDataResponse.arrayBuffer();
        const waveformDataArray = Float64Array.from(new Int16Array(waveformDataBuffer));
        const normalizedDataArray = normalize(waveformDataArray);

        // Wavesurfer may be destroyed the second time user go to episode page, prevent loading in this case
        // and waiting for Wavesurfer to re-init without being destroyed
        if (!wavesurferRef.current.isDestroyed) {
            wavesurferRef.current.load(audioUrl, normalizedDataArray);
        }

        wavesurferRef.current.on('ready', handleAudioFileTotalDuration);
        wavesurferRef.current.on('audioprocess', handleCurrentTime);
        wavesurferRef.current.on('interaction', handleCursorPositionUpdate);
    }, []);

    const handleAudioFileTotalDuration = () => {
        return onDurationChange(wavesurferRef.current?.getDuration());
    };

    const handleCurrentTime = () => {
        return onCurrentTimeChange(wavesurferRef.current?.getCurrentTime());
    };

    const handleCursorPositionUpdate = () => {
        // Need this `setTimeout` because this event is trigger before the cursor position is updated
        return setTimeout(() => {
            onCurrentTimeChange(wavesurferRef.current?.getCurrentTime());
        }, 0);
    };

    const handleCursorPositionUpdated = () => {
        wavesurferRef.current.seekTo(0);
        return wavesurferRef.current.skip(cursorPosition);
    };

    const handlePlayPause = () => {
        return wavesurferRef.current.playPause();
    };

    useEffect(handlePlayPause, [isPlaying]);
    useEffect(handleCursorPositionUpdated, [cursorPosition]);
    useEffect(() => {
        if (!audioRate || !wavesurferRef.current) return;
        wavesurferRef.current.setPlaybackRate(audioRate);
    }, [audioRate]);

    return (
        <WaveformContainer>
            <WaveSurfer plugins={plugins} onMount={handleWSMount} backend="MediaElement">
                <WaveForm
                    id="waveform"
                    progressColor={resolveColor('--primary')}
                    waveColor={resolveColor('--neutral200')}
                    cursorColor={resolveColor('--primary')}
                    cursorWidth={2}
                    hideScrollBar
                    height={WAVE_FORM_HEIGHT}
                    barWidth={2}
                    barGap={2}
                    barRadius={3}
                />
            </WaveSurfer>
        </WaveformContainer>
    );
};

const WaveformContainer = styled.div`
    background: var(--neutral50);
    border-radius: var(--r-m);
    width: 100%;

    canvas {
        max-width: initial;
    }
`;

export default TranscriptionWaveform;
