import { useParams } from 'react-router-dom';
import { ENABLE_PSO } from '@/utils/constants';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import EmptyState from './EmptyState';
import useHasAccessToPso from '@/utils/hooks/useHasAccessToPso.hook';
import TargetKeyword from './TargetKeyword';
import Rules from './Rules';
import Score from './Score';
import SuggestedKeywords from './SuggestedKeywords';
import styled from 'styled-components';

const PsoTab = () => {
    const { showId }: { showId: string; episodeId: string } = useParams();
    const hasAccessToPso = useHasAccessToPso({ showId });
    const psoConfiguration = usePsoQuery({ showId, enabled: ENABLE_PSO });

    if (!hasAccessToPso) {
        return <EmptyState reason="unavailable" />;
    }

    if (!psoConfiguration.data.fullyConfigured) {
        return <EmptyState reason="unconfigured" />;
    }

    return (
        <Wrapper>
            <TargetKeyword />
            <Score />
            <Rules />
            <SuggestedKeywords />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default PsoTab;
