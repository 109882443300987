import { action, runInAction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall } from '../components/app/decorators/api';
import PricesQuotaModel from '../models/PricesQuotaModel';
import NewsletterQuotaModel from '../models/NewsletterQuotaModel';
import {
    updateNewsletterQuota,
    fetchNewsletterQuotaPrice,
    addShowPricesQuota,
    updateShowQuota,
} from '@/api';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { QUOTA_TYPES, SHOW_QUOTA_TYPES, UI_VARIANTS } from '@/utils/constants';

class QuotaStore extends Store {
    static observables = {
        pricesQuota: {},
        newQuota: 0,
        newsletterQuota: {},
    };

    @apiCall
    async addPricesQuota(type, quantity) {
        const addPriceQuota =
            type === QUOTA_TYPES.NEWSLETTER ? fetchNewsletterQuotaPrice : addShowPricesQuota;
        const { data } = await addPriceQuota({ quantity });
        runInAction(() => {
            this.pricesQuota = new PricesQuotaModel(this, data);
        });
    }

    @apiCall
    async updateQuota(type, quantity) {
        try {
            const updateQuota =
                type === QUOTA_TYPES.NEWSLETTER ? updateNewsletterQuota : updateShowQuota;
            /**
             * Floor the quantity to the nearest 1000 for newsletters
             */
            const roundedQuantity =
                type === QUOTA_TYPES.NEWSLETTER ? Math.floor(quantity / 1000) * 1000 : quantity;
            const { data } = await updateQuota({ quantity: roundedQuantity });
            await this.state.subscriptionStore.fetchSubscription();

            if (type === QUOTA_TYPES.NEWSLETTER) {
                runInAction(() => {
                    this.newsletterQuota = new NewsletterQuotaModel(this, data);
                });
            }

            switch (type) {
                case QUOTA_TYPES.NEWSLETTER:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota d'emails a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
                case SHOW_QUOTA_TYPES.ACTIVE_SHOWS:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota d'émissions a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
                default:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
            }
        } catch (e) {
            return modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre quota n'a pu être mis à jour" />,
                {
                    variant: UI_VARIANTS.ALERT,
                    timeout: 5000,
                },
            );
        }
    }

    @action
    setNewQuota(currentQuota) {
        this.newQuota = currentQuota;
    }
}

export default QuotaStore;
