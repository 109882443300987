import { useHistory, useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import EpisodeEditForm from '@app/organisms/EpisodeEditForm/EpisodeEditForm';
import useChaptersQuery from '@queries/chapter/useChaptersQuery.hook';
import EpisodeEditFormContext from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import EpisodeEditFormPageSkeleton from '@app/pages/EpisodeEditFormPage/EpisodeEditFormPageSkeleton';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

const EpisodeEditFormPage = () => {
    const { episodeId } = useParams();
    const episodeQuery = useEpisodeQuery({ episodeId, config: { staleTime: 0 } });
    const chaptersQuery = useChaptersQuery({ episodeId });
    const userQuery = useUserQuery();
    const subscriptionQuery = useSubscriptionQuery();
    const history = useHistory();
    const isError = episodeQuery.error || userQuery.error || subscriptionQuery.error;
    const hasData = episodeQuery.data && userQuery.data && subscriptionQuery.data;

    if (hasData && episodeQuery.isFetchedAfterMount) {
        const { data: episode } = episodeQuery;
        const { data: chapters = [] } = chaptersQuery;

        return (
            <EpisodeEditFormContext episode={episode}>
                <EpisodeEditForm episode={episode} chapters={chapters} />
            </EpisodeEditFormContext>
        );
    }

    if (isError) {
        history.push('/error');
    }

    return <EpisodeEditFormPageSkeleton />;
};

export default EpisodeEditFormPage;
