import { action, runInAction } from 'mobx';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import ClipModel from '../models/ClipModel';
import {
    fetchClipSupportedLanguages,
    fetchClip,
    fetchClipQuotaOfShow,
    updateClip,
    createClip,
    deleteClip,
    fetchClipTranscriptionProgress,
    fetchClipTranscription,
    updateClipTranscription,
    generateClip,
} from '@/api';
import ClipTranscriptionProgressModel from '../models/ClipTranscriptionProgressModel';
import ClipTranscriptionModel from '../models/ClipTranscriptionModel';

class ClipStore extends Store {
    static observables = {
        clips: [],
        pagination: {},

        progress: null,
        transcriptionProgress: null,
        transcription: null,
        transcriptionSupportedLanguages: [],
        quota: {},
    };

    @action.bound
    resetTranscriptionProgress() {
        this.transcriptionProgress = null;
    }

    @apiFetch
    async fetchClip(clipId) {
        const { data } = await fetchClip(clipId);
        const existingClip = this.clips.find((clip) => clip.id === data.id);

        if (!existingClip) {
            const newClip = new ClipModel(this, data);
            this.clips.push(newClip);
            return newClip;
        }

        existingClip.updateData(data);
        return existingClip;
    }

    @action.bound
    createClipForEpisode(episodeId) {
        const clip = new ClipModel(this);
        clip.episodeId = episodeId;
        return clip;
    }

    @apiFetch
    async fetchClipQuota(showId) {
        const { data } = await fetchClipQuotaOfShow(showId);
        runInAction(() => {
            this.quota = data;
        });
    }

    @apiCall
    async updateOrCreateClip(clip, formData) {
        if (clip.id) {
            const { data } = await updateClip(clip.id, formData);
            clip.updateData(data);
            return;
        }
        const { data } = await createClip(clip.episodeId, formData);
        clip.updateData(data);
        runInAction(() => {
            this.clips.unshift(clip);
        });
    }

    @apiCall
    async generateClip(clip) {
        await generateClip(clip.id);
        await clip.refresh();
    }

    @apiCall
    async deleteClip(clip) {
        await deleteClip(clip.id);
        runInAction(() => {
            this.clips.remove(clip);
        });
    }

    @apiFetch
    async fetchTranscriptionProgress(clipId) {
        const { data } = await fetchClipTranscriptionProgress(clipId);
        runInAction(() => {
            this.transcriptionProgress = new ClipTranscriptionProgressModel(this, data);
        });
    }

    @apiFetch
    async fetchTranscription(clipId) {
        const { data } = await fetchClipTranscription(clipId);
        runInAction(() => {
            this.transcription = new ClipTranscriptionModel(this, data);
        });
    }

    @apiCall
    async updateTranscription(clipId, formData) {
        const { data } = await updateClipTranscription(clipId, formData);
        runInAction(() => {
            this.transcription = new ClipTranscriptionModel(this, data);
        });
    }

    @apiFetch
    async fetchTranscriptionSupportedLanguages() {
        const { data } = await fetchClipSupportedLanguages();
        runInAction(() => {
            this.transcriptionSupportedLanguages = Object.entries(data).map(([value, label]) => ({
                value,
                label,
            }));
        });
    }
}

export default ClipStore;
