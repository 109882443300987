import { runInAction } from 'mobx';
import { createTransformer } from 'mobx-utils';
import {
    addPlaylist,
    addPodcastToPlaylist,
    deletePlaylist,
    deletePlaylistImage,
    fetchPlaylist,
    fetchPlaylists,
    moveEpisodesPlaylist,
    removePodcastFromPlaylist,
    updatePlaylist,
    updatePlaylistImage,
} from '@/api';
import PlaylistModel from '../models/PlaylistModel';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import PodcastModel from '../models/PodcastModel';

class PlaylistStore extends Store {
    static observables = {
        playlists: [],
        podcasts: [],
    };

    byId = createTransformer((id) => this.playlists.find((p) => `${p.id}` === `${id}`));
    firstPlaylistOrById = createTransformer(
        (id) => this.byId(id) || (this.playlists.length > 0 ? this.playlists[0] : null),
    );

    @apiFetch
    async fetchPlaylists(showId) {
        const { data } = await fetchPlaylists(showId);
        runInAction(() => {
            this.playlists = data.map((playlist) => new PlaylistModel(this, playlist));
        });
    }

    @apiCall
    async addEpisodesToPlaylist(playlistId, selection) {
        // TODO: We have a problem in the back when the episodes are sent in parallel
        for (let i = 0; i < selection.length; i += 1) {
            const podcastId = selection[i];
            // eslint-disable-next-line no-await-in-loop
            await this.addPodcastToPlaylist(playlistId, podcastId);
        }
    }

    @apiCall
    async addPodcastToPlaylist(playlist, podcastId) {
        const { data } = await addPodcastToPlaylist(playlist.id, podcastId);
        runInAction(() => {
            // TODO: Just WTF API, why is it so complicated to get the podcasts !?
            this.podcasts = data.podcasts.data.map(
                ({ playlistPodcastId, podcast }) =>
                    new PodcastModel(this, { ...podcast.data, playlistPodcastId }),
            );
            playlist.podcastsCount += 1;
        });
    }

    @apiFetch
    async fetchPlaylistPodcasts(playlistId) {
        const { data } = await fetchPlaylist(playlistId);
        runInAction(() => {
            // TODO: Just WTF API, why is it so complicated to get the podcasts !?
            this.podcasts = data.podcasts.data.map(
                ({ playlistPodcastId, podcast }) =>
                    new PodcastModel(this, { ...podcast.data, playlistPodcastId }),
            );
        });
    }

    @apiCall
    moveEpisodesPlaylist(playlist, source, destination) {
        if (!destination) {
            return undefined;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return undefined;
        }

        const prevPodcast = this.podcasts[source.index];
        this.podcasts.splice(source.index, 1);
        this.podcasts.splice(destination.index, 0, prevPodcast);
        return moveEpisodesPlaylist(playlist.id, prevPodcast.playlistPodcastId, {
            new_position: destination.index,
        });
    }

    @apiCall
    async removePodcastFromPlaylist(playlist, podcast) {
        await removePodcastFromPlaylist(playlist.id, podcast.playlistPodcastId);
        runInAction(() => {
            this.podcasts = this.podcasts.filter(
                (p) => p.playlistPodcastId !== podcast.playlistPodcastId,
            );
            playlist.podcastsCount -= 1;
        });
    }

    async addPlaylist(model, { file: image, ...formData }) {
        const { data } = await addPlaylist(model.showId, formData);
        const playlist = new PlaylistModel(this, data);
        runInAction(() => {
            this.playlists.push(playlist);
            model.updateData(data);
        });
        if (image) {
            const imageData = await updatePlaylistImage(playlist.id, image);
            runInAction(() => {
                playlist.imageUrl = imageData.data.url;
            });
        }
    }

    @apiCall
    async deletePlaylist(playlist) {
        await deletePlaylist(playlist.id);
        runInAction(() => {
            this.playlists.remove(playlist);
        });
    }

    @apiCall
    async updatePlaylist(playlist, { file: image, ...formData }) {
        if (image && image !== playlist.imageUrl) {
            const { data } = await updatePlaylistImage(playlist.id, image);
            runInAction(() => {
                playlist.imageUrl = data.url;
            });
        }
        if (image === null) {
            await deletePlaylistImage(playlist.id);
            runInAction(() => {
                playlist.imageUrl = null;
            });
        }
        if (Object.keys(formData).length !== 0) {
            const { data } = await updatePlaylist(playlist.id, formData);
            playlist.updateData(data);
        }
    }
}

export default PlaylistStore;
