import { useRef } from 'react';
import Toast from '../Toast';
import { UI_VARIANTS } from '@/utils/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToastRegion } from '@react-aria/toast';

const toastIconLookup = {
    [UI_VARIANTS.INFO]: icon({ name: 'circle-info', style: 'solid' }),
    [UI_VARIANTS.SUCCESS]: icon({ name: 'circle-check', style: 'solid' }),
    [UI_VARIANTS.ALERT]: icon({ name: 'circle-exclamation', style: 'solid' }),
    [UI_VARIANTS.WARNING]: icon({ name: 'triangle-exclamation', style: 'solid' }),
    [UI_VARIANTS.NEUTRAL]: icon({ name: 'circle-question', style: 'solid' }),
    [UI_VARIANTS.PRIMARY]: icon({ name: 'icons', style: 'solid' }),
};

const toastMessageLookup = defineMessages({
    [UI_VARIANTS.ALERT]: {
        defaultMessage:
            'Oups, les modifications n’ont pas pu être enregistrées. Merci de réessayer. 🙏',
    },
    [UI_VARIANTS.SUCCESS]: { defaultMessage: 'Les modifications ont bien été enregistrées. 👌' },
});

const ToastRegion = ({ state, ...props }) => {
    const intl = useIntl();
    let ref = useRef(null);
    let { regionProps } = useToastRegion(props, state, ref);

    return (
        <ToastStack {...regionProps} ref={ref}>
            {state.visibleToasts.map((toast) => (
                <Toast
                    key={toast.key}
                    state={state}
                    toast={{
                        ...toast,
                        icon:
                            (toast.icon && !toast.variant) ||
                            (toast.variant && (
                                <IconWrapper variant={toast.variant}>
                                    {toast.icon || <Icon icon={toastIconLookup[toast.variant]} />}
                                </IconWrapper>
                            )),
                        content:
                            toast.content || intl.formatMessage(toastMessageLookup[toast.variant]),
                    }}
                />
            ))}
        </ToastStack>
    );
};

const ToastStack = styled.ol`
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: fit-content;
    max-width: calc(100vw - 2rem);

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        max-width: 45rem;
    }
`;
const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--primary50);
    color: var(--primary);

    ${({ variant }) =>
        Object.values(UI_VARIANTS).includes(variant) &&
        css`
            background-color: ${variant === UI_VARIANTS.NEUTRAL
                ? `var(--neutral100)`
                : `var(--${variant}50)}`};
            color: var(--${variant}500);
        `}
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: inherit;
`;

export default ToastRegion;
