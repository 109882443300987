import { useMemo } from 'react';
import {
    Button as AriaButton,
    Calendar as AriaCalendar,
    CalendarCell as AriaCalendarCell,
    CalendarGrid as AriaCalendarGrid,
    CalendarGridBody as AriaCalendarGridBody,
    CalendarGridHeader as AriaCalendarGridHeader,
    CalendarHeaderCell as AriaCalendarHeaderCell,
    DateInput,
    DatePicker as AriaDatePicker,
    Dialog as AriaDialog,
    Group,
    Heading,
    Popover,
    DateSegment,
    DatePickerProps as AriaDatePickerProps,
    DateValue,
    TooltipTrigger,
} from 'react-aria-components';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Text from '../Text';
import { FormattedMessage } from 'react-intl';
import { getLocalTimeZone, isToday, today } from '@internationalized/date';
import Tooltip, { TooltipTriggerWrapper } from '../../Tooltip';

/* 
    cf. react-aria DatePicker: https://react-spectrum.adobe.com/react-aria/DatePicker.html#props
    cf. react-aria Calendar: https://react-spectrum.adobe.com/react-aria/Calendar.html#props
*/
interface DatePickerProps extends AriaDatePickerProps<DateValue> {
    errorMessage?: string;
    hideCustomTimezone?: boolean;
    isOptional?: boolean;
    isRequired?: boolean;
    tooltip?: string | React.ReactNode;
    description?: string;
    label?: string | React.ReactNode;
    validate?: (value: DateValue) => string | undefined;
}

const DatePicker = ({
    label,
    errorMessage,
    tooltip,
    isOptional,
    isRequired,
    description,
    onChange,
    defaultValue,
    value,
    hideCustomTimezone,
    ...props
}: DatePickerProps) => {
    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const handleChangeDate = (date: DateValue) => {
        onChange?.(date);
    };

    return (
        <DatePickerStyled
            {...props}
            onChange={handleChangeDate}
            granularity="day"
            value={value || today(timezone)}
            shouldCloseOnSelect={false}
        >
            <Label
                isOptional={isOptional}
                isRequired={isRequired}
                tooltip={tooltip}
                description={description}
            >
                {label}
            </Label>
            <InputWrapper>
                <TriggerPopOverButton>
                    <CalendarIcon icon={icon({ name: 'calendar', style: 'regular' })} />
                    <InputDate>{(segment) => <DateSegment segment={segment} />}</InputDate>
                </TriggerPopOverButton>
            </InputWrapper>
            <Popover>
                <Dialog>
                    <Calendar visibleDuration={{ months: 1 }}>
                        <HeadingWrapper>
                            <ArrowButton slot="previous">
                                <ArrowIcon icon={icon({ name: 'arrow-left', style: 'regular' })} />
                            </ArrowButton>
                            <HeadingStyled />
                            <ArrowButton slot="next">
                                <ArrowIcon icon={icon({ name: 'arrow-right', style: 'regular' })} />
                            </ArrowButton>
                        </HeadingWrapper>
                        <CalendarGrid>
                            <AriaCalendarGridHeader>
                                {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
                            </AriaCalendarGridHeader>
                            <CalendarGridBody>
                                {(date) => (
                                    <CalendarBodyCell
                                        date={date}
                                        $isTodayDate={isToday(date, timezone)}
                                    />
                                )}
                            </CalendarGridBody>
                        </CalendarGrid>
                    </Calendar>
                </Dialog>
            </Popover>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {timezone && !hideCustomTimezone && (
                <TimezoneWrapper>
                    <Text variant="bodyS" color="--neutral500">
                        {timezone}
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="bottom">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="La modification du fuseau horaire de publication est accessible depuis votre compte." />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                </TimezoneWrapper>
            )}
        </DatePickerStyled>
    );
};

const DatePickerStyled = styled(AriaDatePicker)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    max-width: 21.25rem;
    min-width: 145px;
`;
const InputWrapper = styled(Group)`
    background-color: var(--white);
    border-radius: var(--r-s);
    border: solid 1px var(--neutral200);

    &[data-focus-within] {
        transition-duration: 0.2s;
        border-color: var(--primary);
        outline: 0.25rem var(--primary50) solid;
    }

    &[data-disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
`;
const InputDate = styled(DateInput)`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: text;
`;
const TriggerPopOverButton = styled(AriaButton)`
    padding: 0.625rem;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const IconButton = styled(AriaButton)`
    padding: 0;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const CalendarIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--black);
`;
const Dialog = styled(AriaDialog)`
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--r-m);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
`;
const Calendar = styled(AriaCalendar)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ArrowButton = styled(IconButton)`
    padding: 0.5rem;
`;
const ArrowIcon = CalendarIcon;
const HeadingStyled = styled(Heading)`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
    color: var(--black);
`;
const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
`;
const CalendarGrid = styled(AriaCalendarGrid)`
    width: 100%;
    flex: 1;
    border-spacing: 0.25rem;

    td,
    th {
        padding: 0;
    }
`;
const CalendarGridBody = styled(AriaCalendarGridBody)`
    text-align: center;
    vertical-align: middle;
`;
const CalendarHeaderCell = styled(AriaCalendarHeaderCell)`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    font-weight: var(--fw-normal);
`;
const CalendarBodyCell = styled(AriaCalendarCell)<{ $isTodayDate: boolean }>`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
    border-radius: var(--r-s);
    cursor: pointer;

    &:hover {
        background-color: var(--primary50);
        color: var(--primary);
    }

    &[data-focus-visible],
    &[data-focused] {
        outline: solid 0.25rem var(--primary50);
    }

    &[data-selected] {
        background-color: var(--primary);
        color: var(--white);
    }

    &[data-disabled] {
        opacity: 0.5;
    }

    &[data-disabled],
    &[data-outside-month] {
        color: var(--neutral500);
    }

    ${({ $isTodayDate }) =>
        $isTodayDate &&
        css`
            position: relative;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0.25rem;
                height: 0.25rem;
                border-radius: var(--r-full);
                background-color: var(--primary);
            }
        `}
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const TimezoneWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;

export default DatePicker;
