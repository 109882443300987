import { createApi } from '../callApi';
import { CustomPrompt } from '@queries/ai/useUpdateAICustomPromptsMutation.hook';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { SocialNetwork } from '@/utils/constants';

const contentApi = createApi('CONTENT');

export const fetchAITitleSuggestions = (episodeId: number, settings: AICustomPromptFormSchema) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/title`,
        body: settings,
    });
};

export const fetchAIDescriptionSuggestion = (
    episodeId: number,
    settings: AICustomPromptFormSchema,
) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/description`,
        body: settings,
    });
};

export const fetchAINewsletterSuggestion = (
    episodeId: number,
    settings: AICustomPromptFormSchema,
) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/newsletter`,
        body: settings,
    });
};

export const fetchAITagsSuggestions = (episodeId: number, settings: AICustomPromptFormSchema) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/tags`,
        body: settings,
    });
};

export const fetchAIChaptersSuggestions = (episodeId: number) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/chapters`,
    });
};

export const fetchAISocialPostSuggestion = (
    episodeId: number,
    provider: SocialNetwork,
    settings: AICustomPromptFormSchema,
) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/ai_suggestions/social_media_post/${provider}`,
        body: settings,
    });
};

export const fetchAISummary = (episodeId: number) => {
    return contentApi({
        method: 'GET',
        target: `/v1/podcasts/${episodeId}/summary`,
    });
};

export const enableAIFreeTrial = (episodeId: number) => {
    return contentApi({
        method: 'POST',
        target: '/v1/user/channel/ai_free_trial_podcast',
        body: {
            podcast_id: episodeId,
        },
    });
};

export const fetchAICustomPrompts = (showId: number) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/custom_prompts`,
    });
};

export const updateAICustomPrompts = (showId: number, customPrompts: CustomPrompt[]) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/shows/${showId}/custom_prompts`,
        body: { custom_prompts: customPrompts },
    });
};
