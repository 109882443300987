import LogoFull from '@public/logos/logo-color-full.svg';
import styled from 'styled-components';
import illlustration from '@public/images/error-O5H4.png';
import Stack from '../ui/layout/Stack';
import Text from '../ui/atoms/Text';
import Droplet from '@public/images/droplet.svg';
import { motion } from 'framer-motion';

interface ErrorProps {
    title: string | React.ReactElement;
    subtitle?: string | React.ReactElement;
    message: string | React.ReactElement;
    action?: React.ReactElement;
}

const Error = ({ title, subtitle, message, action }: ErrorProps) => {
    return (
        <Wrapper>
            <Logo />
            <Stack $gap="2rem" $align="center">
                <IllustrationWithDivider>
                    <IllustrationWrapper>
                        <AnimatedDroplet
                            initial={{ opacity: 0, y: -16 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.75, // 750ms
                                delay: 1, // 1000ms
                                ease: 'easeOut',
                            }}
                        >
                            <Droplet />
                        </AnimatedDroplet>
                        <Illustration src={illlustration} alt="Error" />
                    </IllustrationWrapper>
                    <Divider />
                </IllustrationWithDivider>
                <Stack $gap="0.5rem" $align="center">
                    <Text variant="headingXL" fontWeight="--fw-bold">
                        {title}
                    </Text>
                    <Text variant="bodyL" color="--neutral500" fontWeight="--fw-normal">
                        {subtitle}
                    </Text>
                </Stack>
                <Text variant="bodyL">{message}</Text>
                {action}
            </Stack>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    text-align: center;
    max-width: 600px;
    padding-block: 2rem;
`;
const Logo = styled(LogoFull)`
    width: 9rem;
`;
const IllustrationWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 20rem;
`;
const Illustration = styled.img`
    width: 100%;
`;
const AnimatedDroplet = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 35%;
    width: 1.25rem;
    height: 1.75rem;
`;
const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--neutral100);
`;
const IllustrationWithDivider = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export default Error;
