import { DEFAULT_SPEAKER_NAME_REGEX } from '@app/molecules/TranscriptionEditor/TranscriptionSpeaker/TranscriptionSpeakerNameMessage';
import { useIntl } from 'react-intl';
import {
    SpeakerMap,
    useSpeakers,
} from '@app/molecules/TranscriptionEditor/TranscriptionSpeakersProvider';
import { IntlShape } from 'react-intl/src/types';

interface Word {
    word: string;
    start: number;
    end: number;
    confidence: number;
}

interface Utterance {
    text: string;
    start: number;
    end: number;
    confidence: number;
    speaker: number | null;
    words: Word[];
}

interface Transcript {
    utterances: Utterance[];
    speakers: Speaker[];
}

interface TextNode {
    type: 'text';
    text: string;
}

interface WordNodeAttrs {
    start: number;
    end: number;
    confidence: number;
}

interface WordNode {
    type: 'word';
    attrs: WordNodeAttrs;
    content: TextNode[];
}

interface PhraseNodeAttrs {
    text: string;
    start: number;
    end: number;
    confidence: number;
    speaker: number | null;
}

interface PhraseNode {
    type: 'phrase';
    attrs: PhraseNodeAttrs;
    content: WordNode[];
}

interface ParagraphNodeAttrs {
    speaker: number | null;
}

interface ParagraphNode {
    type: 'paragraph';
    attrs: ParagraphNodeAttrs;
    content: PhraseNode[];
}

interface DocumentNode {
    type: 'doc';
    content: ParagraphNode[];
}

export interface Speaker {
    id: number;
    name: string;
}

const createWordNode = ({ word, start, end, confidence }: Word): WordNode => ({
    type: 'word',
    attrs: { start, end, confidence },
    content: [{ type: 'text', text: word }],
});

const createPhraseNode = (words: Word[], attrs: PhraseNodeAttrs): PhraseNode => ({
    type: 'phrase',
    attrs,
    content: words.map(createWordNode),
});

const createParagraphNode = (speaker: number | null, content: PhraseNode[]): ParagraphNode => ({
    type: 'paragraph',
    attrs: { speaker },
    content,
});

const trimFirstWord = (words: Word[]): Word[] => {
    return words.length > 0
        ? [{ ...words[0], word: words[0].word.trimStart() }, ...words.slice(1)]
        : words;
};

export const convertTranscriptToTiptapContent = (transcript: Transcript): DocumentNode => {
    const content = transcript.utterances.reduce<ParagraphNode[]>((accumulator, utterance) => {
        let { words, start, end, text, speaker, confidence } = utterance;

        // Determine if the current utterance continues the conversation of the last paragraph's speaker.
        const lastParagraph = accumulator[accumulator.length - 1];
        const isSameSpeaker = lastParagraph?.attrs.speaker === speaker;

        if (!isSameSpeaker) {
            // Trim the first word if starting a new paragraph.
            words = trimFirstWord(words);
        }

        const phrase = createPhraseNode(words, { start, end, text, speaker, confidence });

        if (isSameSpeaker) {
            // Append the current phrase to the last paragraph for the same speaker.
            lastParagraph.content.push(phrase);
        } else {
            // Start a new paragraph for a new speaker.
            accumulator.push(createParagraphNode(speaker, [phrase]));
        }

        return accumulator;
    }, []);

    return { type: 'doc', content };
};

export const convertTiptapContentToTranscript = (
    documentNode: DocumentNode,
    speakers: Speaker[],
): Transcript => {
    const utterances: Utterance[] = documentNode.content.flatMap((paragraph) =>
        paragraph.content.map((phrase) => ({
            // Words already contain spaces, no additional space is required when joining
            text: phrase.content.map((word) => word.content[0].text).join(''),
            start: phrase.attrs.start,
            end: phrase.attrs.end,
            confidence: phrase.attrs.confidence,
            // Use paragraph speaker for all phrases
            speaker: paragraph.attrs.speaker,
            words: phrase.content.map((word) => ({
                word: word.content[0].text,
                start: word.attrs.start,
                end: word.attrs.end,
                confidence: word.attrs.confidence,
            })),
        })),
    );

    return { utterances, speakers };
};

export const useConvertTiptapContentToPlainText = (
    documentNode: DocumentNode,
    speakers: SpeakerMap,
    intl: IntlShape,
) => {
    let plainText = '';
    let lastSpeaker = -1;

    documentNode.content.flatMap((paragraph) => {
        paragraph.content.map((phrase) => {
            const phraseSpeaker =
                phrase.attrs.speaker === undefined || phrase.attrs.speaker === null
                    ? -1
                    : phrase.attrs.speaker;
            let speaker = '';

            if (phraseSpeaker === -1) {
                speaker = intl.formatMessage({ defaultMessage: 'Aucun orateur' });
            }
            if (phraseSpeaker !== lastSpeaker) {
                const speakerName = speakers.get(phraseSpeaker) || '';
                speaker = DEFAULT_SPEAKER_NAME_REGEX.test(speakerName)
                    ? intl.formatMessage(
                          { defaultMessage: 'Orateur {speakerName}' },
                          { speakerName: speakerName },
                      )
                    : speakerName;
            }

            speaker = speaker ? speaker + '\n' : '';
            plainText =
                plainText + speaker + phrase.content.map((word) => word.content[0].text).join('');
            lastSpeaker = phraseSpeaker;
        });
        plainText = plainText + '\n\n';
    });

    return plainText;
};
