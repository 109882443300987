import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAISummary } from '@/api';
import aiKeys from './aiKeys';
import { AxiosError } from 'axios';

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.summaryDetails>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    await fetchAISummary(episodeId);
    // Return null as the response is not needed
    return null;
};

interface AISummaryQueryProps {
    episodeId: string;
    options?: {
        enabled?: boolean;
    };
}

const useAISummaryQuery = ({ episodeId, options }: AISummaryQueryProps) => {
    return useQuery({
        queryKey: aiKeys.summaryDetails(episodeId),
        queryFn,
        enabled: options?.enabled,
        retry: (_, error) => {
            // Retry fetching if the response status is not 200
            return (error as AxiosError).response?.status !== 200;
        },
        retryDelay: 1000,
        refetchOnWindowFocus: false,
    });
};

export default useAISummaryQuery;
