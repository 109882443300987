import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import { useResponsive } from '@/utils/hooks/useResponsive';
import supersonicImage from '@public/images/plans/supersonic.png';
import launchImage from '@public/images/plans/launch.png';
import boostImage from '@public/images/plans/boost.png';
import {
    getPriceFromNumberOrString,
    getYearlyBillingPriceForOneMonth,
    PRICING,
} from '@/utils/pricing';
import PricingTitles from './PricingTitles';
import PricingControls from './PricingControls';
import PricingOffer from './PricingOffer';
import PricingCompare from './PricingCompare';
import PricingReviews from './PricingReviews';
import PricingOfferFeature from './PricingOfferFeature';
import PricingContext from '../PricingContext';
import Center from '@ui/layout/Center';
import useUserStore from '@/utils/hooks/useUserStore';
import Text from '@ui/atoms/Text';
import AushaPro from './AushaPro';
import type { Offer } from '@/queries/price/usePricesQuery.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import styled from 'styled-components';
import EpisodeAIPricing from '@/components/EpisodeAI/EpisodeAIPricing';
import { ENABLE_EPISODE_AI } from '@/utils/constants';

interface PricingOfferFeatureTooltipProps {
    children: React.ReactNode;
}

const PricingOfferFeatureTooltip = ({ children }: PricingOfferFeatureTooltipProps) => (
    <TooltipTrigger delay={0} closeDelay={0}>
        <TooltipTriggerWrapper>
            <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
        </TooltipTriggerWrapper>
        <Tooltip placement="top">
            <Text variant="bodyS" fontWeight="--fw-semibold">
                {children}
            </Text>
        </Tooltip>
    </TooltipTrigger>
);

const LaunchFeatures = () => {
    const { user } = useUserStore();

    return (
        <>
            <PricingOfferFeature isHighlighted>
                <FormattedMessage defaultMessage="Stockage, épisodes et audience illimités" />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="Diffusion automatique sur toutes les plateformes d’écoute {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Distribuez votre podcast sur Apple Podcasts, Deezer, Spotify, Podcast Addict, Amazon etc." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="Gestion des réseaux sociaux (Twitter, Facebook) {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Grâce au Social Media Manager, publiez, planifiez et analysez vos posts directement depuis Ausha." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="Multiples outils de communication {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Site web Podcast, Smartplayer, SmartLink, Newsletter…" />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage defaultMessage="Statistiques essentielles" />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage defaultMessage="Audience par pays" />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage defaultMessage="Comparaison des audiences de vos épisodes (3 derniers)" />
            </PricingOfferFeature>
            {user?.languageCode === 'fr' && (
                <PricingOfferFeature>
                    <FormattedMessage
                        defaultMessage="Accès à la régie de monétisation Auto {tooltip}"
                        values={{
                            tooltip: (
                                <PricingOfferFeatureTooltip>
                                    <FormattedMessage defaultMessage="À partir de 5000 écoutes sur 30 jours, activez la monétisation automatique et rejoignez notre régie Targetspot." />
                                </PricingOfferFeatureTooltip>
                            ),
                        }}
                    />
                </PricingOfferFeature>
            )}
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="Transcription : 60min / mois {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="La transcription AI transforme l’audio en texte dans 99 langues, mettant à jour automatiquement le flux RSS et le site web de votre podcast pour booster le SEO." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage defaultMessage="4 Clips vidéo / mois avec transcription" />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="2 émissions archivées {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Vous souhaitez créer une nouvelle émission et ne mettez plus à jour l’ancienne ? Au lieu de la supprimer, archivez-la et elle restera toujours disponible à l’écoute !" />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            <PricingOfferFeature>
                <FormattedMessage
                    defaultMessage="{userCount, plural, one {# utilisateur {tooltip}} other {# utilisateurs {tooltip}}}"
                    values={{
                        userCount: 1,
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Les utilisateurs peuvent consulter ou gérer les données de l'émission selon différents rôles : Lecteur, Éditeur ou Admin." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
            {user?.languageCode === 'fr' ? (
                <PricingOfferFeature>
                    <FormattedMessage
                        defaultMessage="Accès au Club Ausha {tooltip}"
                        values={{
                            tooltip: (
                                <PricingOfferFeatureTooltip>
                                    <FormattedMessage defaultMessage="Le Club Ausha est un espace communautaire privé pour s’entraider, se rencontrer, créer des partenariats de visibilité et accéder à des événements et contenus exclusifs" />
                                </PricingOfferFeatureTooltip>
                            ),
                        }}
                    />
                </PricingOfferFeature>
            ) : null}
            {ENABLE_EPISODE_AI && (
                <PricingOfferFeature isMagic>
                    <FormattedMessage
                        defaultMessage="Add-on d'Ausha Intelligence disponible {tooltip}"
                        values={{
                            tooltip: (
                                <PricingOfferFeatureTooltip>
                                    <FormattedMessage defaultMessage="Ajoutez Ausha Intelligence et laissez la magie de AI suggérer le contenu de votre podcast." />
                                </PricingOfferFeatureTooltip>
                            ),
                        }}
                    />
                </PricingOfferFeature>
            )}
        </>
    );
};

const BoostFeatures = () => (
    <>
        <PricingOfferFeature isAdditional>
            <FormattedMessage
                defaultMessage="Diffusion YouTube et SoundCloud {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Vos épisodes sont convertis en vidéos YouTube affichant la pochette de votre podcast et le nom de votre épisode ainsi qu’en audio pour SoundCloud." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage defaultMessage="Gestion des réseaux sociaux (Twitter, Facebook, LinkedIn, Instagram)" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage
                defaultMessage="Statistiques avancées {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Auditeurs uniques, PeakTime, suivi classement Apple Podcasts, Audience par OS et browser" />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage defaultMessage="Audience par région" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage defaultMessage="Lien d'écoute personnalisé" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage defaultMessage="Comparaison des audiences de vos épisodes (5 au choix)" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional>
            <FormattedMessage defaultMessage="Outil de monétisation manuelle" />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage
                defaultMessage="Transcription : 120min / mois {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="La transcription AI transforme l’audio en texte dans 99 langues, mettant à jour automatiquement le flux RSS et le site web de votre podcast pour booster le SEO." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage defaultMessage="12 Clips vidéo / mois avec transcription" />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage defaultMessage="3 émissions archivées" />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage
                defaultMessage="{userCount, plural, one {# utilisateur {tooltip}} other {# utilisateurs {tooltip}}}"
                values={{
                    userCount: 3,
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Les utilisateurs peuvent consulter ou gérer les données de l'émission selon différents rôles : Lecteur, Éditeur ou Admin." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        {ENABLE_EPISODE_AI && (
            <PricingOfferFeature isMagic>
                <FormattedMessage
                    defaultMessage="Add-on d'Ausha Intelligence disponible {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Ajoutez Ausha Intelligence et laissez la magie de AI suggérer le contenu de votre podcast." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
        )}
    </>
);

const SupersonicFeatures = () => (
    <>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage defaultMessage="Playlist privée avec mot de passe" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage defaultMessage="Statistiques démographiques" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage defaultMessage="Audience par ville" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage defaultMessage="Comparaison des audiences de vos épisodes (7 au choix)" />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage
                defaultMessage="CTA Manager {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Ajoutez des boutons personnalisés sur vos supports de communication Ausha" />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature isAdditional iconColor="--primary">
            <FormattedMessage
                defaultMessage="API / Webhooks {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Accédez à une API sécurisée et complète et aux webhooks pour connecter vos applications entre elles." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage
                defaultMessage="Transcription : 240min / mois {tooltip}"
                values={{
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="La transcription AI transforme l’audio en texte dans 99 langues, mettant à jour automatiquement le flux RSS et le site web de votre podcast pour booster le SEO." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage defaultMessage="24 Clips vidéo / mois avec transcription" />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage defaultMessage="5 émissions archivées" />
        </PricingOfferFeature>
        <PricingOfferFeature>
            <FormattedMessage
                defaultMessage="{userCount, plural, one {# utilisateur {tooltip}} other {# utilisateurs {tooltip}}}"
                values={{
                    userCount: 5,
                    tooltip: (
                        <PricingOfferFeatureTooltip>
                            <FormattedMessage defaultMessage="Les utilisateurs peuvent consulter ou gérer les données de l'émission selon différents rôles : Lecteur, Éditeur ou Admin." />
                        </PricingOfferFeatureTooltip>
                    ),
                }}
            />
        </PricingOfferFeature>
        {ENABLE_EPISODE_AI && (
            <PricingOfferFeature isMagic>
                <FormattedMessage
                    defaultMessage="Add-on d'Ausha Intelligence disponible {tooltip}"
                    values={{
                        tooltip: (
                            <PricingOfferFeatureTooltip>
                                <FormattedMessage defaultMessage="Ajoutez Ausha Intelligence et laissez la magie de AI suggérer le contenu de votre podcast." />
                            </PricingOfferFeatureTooltip>
                        ),
                    }}
                />
            </PricingOfferFeature>
        )}
    </>
);

const PricingOffers = () => {
    useAmplitudeLogEvent('Subscription Plans Viewed');
    const { isDesktop } = useResponsive();

    const {
        getPricesOfCurrencyAndProduct,
        isCurrentSubscriptionActive,
        currentSubscriptionPricing,
        isYearlyPeriodicitySelected,
        selectedCurrency,
    } = useContext(PricingContext);

    const getOfferPrice = (offer: Offer) => {
        if (!selectedCurrency) return;
        if (isYearlyPeriodicitySelected) {
            const yearlyPrice = getPricesOfCurrencyAndProduct(selectedCurrency, offer)?.yearly
                ?.base;
            return getYearlyBillingPriceForOneMonth(yearlyPrice);
        }

        return getPriceFromNumberOrString(
            getPricesOfCurrencyAndProduct(selectedCurrency, offer)?.monthly?.base,
        );
    };

    return (
        // @ts-ignore
        <Center max="72.5rem">
            <Stack $gap={isDesktop ? '4rem' : '2.5rem'}>
                <Stack $gap={isDesktop ? '4rem' : '2.5rem'}>
                    <Stack $gap={isDesktop ? '5.5rem' : '4rem'}>
                        <Stack $gap="2rem">
                            <PricingTitles />
                            <PricingControls displayCurrency={!isCurrentSubscriptionActive} />
                        </Stack>
                        <PricingOfferGroup>
                            <PricingOffer
                                offer={PRICING.LAUNCH as Offer}
                                offerDescription={
                                    <FormattedMessage defaultMessage="Idéal pour se lancer facilement" />
                                }
                                offerImage={launchImage}
                                offerPrice={getOfferPrice(PRICING.LAUNCH as Offer)}
                                isCurrentOffer={
                                    isCurrentSubscriptionActive &&
                                    currentSubscriptionPricing === PRICING.LAUNCH
                                }
                            >
                                <Stack $gap="1rem">
                                    <LaunchFeatures />
                                </Stack>
                            </PricingOffer>
                            <PricingOffer
                                offer={PRICING.BOOST as Offer}
                                offerDescription={
                                    <FormattedMessage defaultMessage="Idéal pour booster son audience" />
                                }
                                offerImage={boostImage}
                                offerPrice={getOfferPrice(PRICING.BOOST as Offer)}
                                isCurrentOffer={
                                    isCurrentSubscriptionActive &&
                                    currentSubscriptionPricing === PRICING.BOOST
                                }
                                isHighlighted={!isCurrentSubscriptionActive}
                            >
                                <Stack $gap="1rem">
                                    <Text fontWeight="--fw-bold">
                                        <FormattedMessage defaultMessage="Toutes les fonctionnalités du plan Launch avec en plus :" />
                                    </Text>
                                    <BoostFeatures />
                                </Stack>
                            </PricingOffer>
                            <PricingOffer
                                offer={PRICING.SUPERSONIC as Offer}
                                offerDescription={
                                    <FormattedMessage defaultMessage="Idéal pour propulser son podcast" />
                                }
                                offerImage={supersonicImage}
                                offerPrice={getOfferPrice(PRICING.SUPERSONIC as Offer)}
                                isCurrentOffer={
                                    isCurrentSubscriptionActive &&
                                    currentSubscriptionPricing === PRICING.SUPERSONIC
                                }
                            >
                                <Stack $gap="1rem">
                                    <Text fontWeight="--fw-bold">
                                        <FormattedMessage defaultMessage="Toutes les fonctionnalités du plan Boost avec en plus :" />
                                    </Text>
                                    <SupersonicFeatures />
                                </Stack>
                            </PricingOffer>
                        </PricingOfferGroup>
                    </Stack>
                    <PricingCompare />
                </Stack>
                <Stack $gap="2.5rem">
                    {ENABLE_EPISODE_AI && <EpisodeAIPricing />}
                    <AushaPro />
                    <PricingReviews />
                </Stack>
            </Stack>
        </Center>
    );
};

const PricingOfferGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    column-gap: 1rem;
    row-gap: 4rem;

    > * {
        flex-grow: 1;
        flex-basis: calc((46rem - 100%) * 999);
    }
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default PricingOffers;
