import type { TooltipProps } from 'react-aria-components';
import { OverlayArrow, Tooltip as ReactAriaTooltip } from 'react-aria-components';
import styled, { css } from 'styled-components';

interface MyTooltipProps extends Omit<TooltipProps, 'children'> {
    children: React.ReactNode;
    passthrough?: boolean;
    [key: string]: any;
    variant?: 'default' | 'light';
}

const Tooltip = ({ children, passthrough, variant, ...props }: MyTooltipProps) => {
    if (passthrough) {
        return null;
    }
    return (
        <StyledTooltip variant={variant ?? 'default'} offset={8} {...props}>
            <StyledOverlayArrow variant={variant ?? 'default'}>
                <svg width={8} height={8} viewBox="0 0 8 8">
                    <path d="M0 0 L4 4 L8 0" />
                </svg>
            </StyledOverlayArrow>
            {children}
        </StyledTooltip>
    );
};

const StyledTooltip = styled(ReactAriaTooltip)<{ variant: 'default' | 'light' }>`
    background-color: var(--black);
    color: var(--white);
    padding: 0.5rem;
    border-radius: var(--r-m);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    max-width: 20rem;
    text-align: center;

    ${({ variant }) =>
        variant === 'light' &&
        css`
            background-color: var(--white);
            color: var(--black);
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
        `};
`;
const StyledOverlayArrow = styled(OverlayArrow)<{
    variant: 'default' | 'light';
}>`
    width: 0.5rem;
    height: 0.5rem;

    &[data-placement='bottom'] > svg {
        transform: rotate(180deg);
    }

    &[data-placement='left'] > svg {
        transform: rotate(-90deg);
    }

    &[data-placement='right'] > svg {
        transform: rotate(90deg);
    }

    ${({ variant }) =>
        variant === 'light' &&
        css`
            & svg {
                fill: var(--white);
            }
        `};
`;

export default Tooltip;
