import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import GiftIcon from '@public/icons/gift-nebula.svg';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import illustration from '@public/images/ai-pricing.webp';
import EpisodeAIPricingAction from './EpisodeAIPricingAction';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/utils/userOptions';
import Badge from '@/components/ui/atoms/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import usePricesQuery from '@/queries/price/usePricesQuery.hook';
import AushaIntelligenceLogo from '@public/logos/ausha-intelligence.svg';
import { PRICING } from '@/utils/pricing';

// TODO: Ask backend to send integer values instead of strings for prices
const EpisodeAIPricing = () => {
    const user = useUserQuery();
    const subscription = useSubscriptionQuery();
    const prices = usePricesQuery();
    const currency = subscription.data?.currency ?? 'usd';
    const monthlyPrice = prices.data?.[currency]?.episodeAi?.monthly?.base;
    const yearlyPrice = prices.data?.[currency]?.episodeAi?.yearly?.base;
    const monthlyPriceInt = (monthlyPrice &&
        parseInt(monthlyPrice.replace(/[^\d]/g, ''), 10)) as number;
    const yearlyPriceInt = (yearlyPrice &&
        parseInt(yearlyPrice.replace(/[^\d]/g, ''), 10)) as number;
    const hasActiveAddOn = user.data?.options.includes(USER_OPTIONS.EPISODE_AI);
    const hasCancelledAddOn =
        hasActiveAddOn &&
        !subscription.data?.extraItems?.some((item) => item.product === 'episode_ai');
    const currentItemPrice = subscription.data?.extraItems.find(
        (item) => item.product === 'episode_ai',
    )?.price?.base;
    const currentItemPriceInt = (currentItemPrice &&
        parseInt(currentItemPrice.replace(/[^\d]/g, ''), 10)) as number;
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;

    return (
        <Container>
            <Stack $gap="2rem">
                <Stack $gap="1.5rem">
                    <Header>
                        <HeaderInner>
                            <Text color="--neutral500" fontWeight="--fw-semibold">
                                Add-on
                            </Text>
                            {hasActiveAddOn && (
                                <Badge
                                    variant="success"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'circle-check', style: 'solid' })}
                                        />
                                    }
                                >
                                    <FormattedMessage defaultMessage="Ajouté" />
                                </Badge>
                            )}
                        </HeaderInner>
                        <AILogo />
                    </Header>
                    <BulletList>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de <b>titres d'épisodes, descriptions, tags, chapitres et newsletters.</b>"
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de posts sur les <b>réseaux sociaux.</b>"
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Tonalité et préférences</b> de la génération AI personnalisables."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconGift />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>+60 minutes</b> de Transcription additionnelles par mois."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                    </BulletList>
                    {hasCancelledAddOn || isPro ? null : hasActiveAddOn ? (
                        <Alert data-testid="pricing-ai-freemium">
                            <AlertIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="L'add-on AI a bien été ajouté à votre offre. Vous serez facturé {amount} de plus sur votre prochaine facture."
                                    values={{
                                        amount: (
                                            <FormattedNumber
                                                value={currentItemPriceInt}
                                                style="currency"
                                                currency={currency}
                                                maximumFractionDigits={0}
                                                currencyDisplay="narrowSymbol"
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                        </Alert>
                    ) : (
                        <Stack $gap="0.25rem">
                            <Text variant="headingL" fontWeight="--fw-bold">
                                <FormattedMessage
                                    defaultMessage="{amount} /mois"
                                    values={{
                                        amount: (
                                            <FormattedNumber
                                                value={yearlyPriceInt / 12}
                                                style="currency"
                                                currency={currency}
                                                currencyDisplay="narrowSymbol"
                                                maximumFractionDigits={
                                                    (yearlyPriceInt / 12) % 1 === 0 ? 0 : 2
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Facturation annuelle ou {amount} par mois."
                                    values={{
                                        amount: (
                                            <FormattedNumber
                                                value={monthlyPriceInt}
                                                style="currency"
                                                currency={currency}
                                                currencyDisplay="narrowSymbol"
                                                maximumFractionDigits={
                                                    monthlyPriceInt % 1 === 0 ? 0 : 2
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                        </Stack>
                    )}
                </Stack>
                <EpisodeAIPricingAction />
            </Stack>
            <IllustrationWrapper>
                <Illustration src={illustration} alt="Ausha Intelligence" />
            </IllustrationWrapper>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    background: var(--gradient-aurora);
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;

        & > :first-child {
            flex-grow: 1;
        }
        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const Header = styled.header`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const HeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
`;
const AILogo = styled(AushaIntelligenceLogo)`
    width: 100%;
    max-width: 308px;
    height: 2rem;
`;
const BulletList = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    list-style: none;
    padding: 0;
`;
const BulletPoint = styled.li`
    display: flex;
    column-gap: 0.5rem;
`;
const IconSparkles = styled(SparklesIcon)`
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-top: 0.25rem;
`;
const AlertIcon = styled(FontAwesomeIcon)`
    margin-top: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
`;
const IconGift = styled(GiftIcon)`
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-top: 0.25rem;
`;
const Alert = styled.div`
    display: flex;
    column-gap: 0.5rem;
    color: var(--info500);
`;
const IllustrationWrapper = styled.div`
    align-self: stretch;
    border-radius: var(--r-m);
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    min-height: 348px;
    max-width: 25rem;
    overflow: hidden;
    position: relative;
`;
const Illustration = styled.img`
    max-width: calc(100% - 4rem);
    position: absolute;
    left: calc(50% + 1rem);
    bottom: -4rem;
    transform: translateX(-50%);
`;

export default EpisodeAIPricing;
