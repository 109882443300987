import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface Media {
    name: string;
    url: string;
}

interface TAudioPlayerContext {
    currentlyPlaying: Media | null;
    setCurrentlyPlaying: (source: Media | null) => void;
    audioIsPlaying: boolean;
    audioIsPaused: boolean;
    play: (source: Media) => void;
    pause: () => void;
}

const AudioPlayerContext = createContext<TAudioPlayerContext | undefined>(undefined);

interface AudioPlayerContextProviderProps {
    children: ReactNode;
}

export const AudioPlayerContextProvider: FC<AudioPlayerContextProviderProps> = ({ children }) => {
    const [currentlyPlaying, setCurrentlyPlaying] = useState<Media | null>(null);
    const [audioIsPlaying, setAudioIsPlaying] = useState(false);
    const [audioIsPaused, setAudioIsPaused] = useState(false);

    const play = (source: Media) => {
        if (!source) return;
        setCurrentlyPlaying(source);
        setAudioIsPlaying(true);
        setAudioIsPaused(false);
    };
    const pause = () => {
        setAudioIsPlaying(false);
        setAudioIsPaused(true);
    };

    const contextValue = {
        currentlyPlaying,
        setCurrentlyPlaying,
        audioIsPlaying,
        audioIsPaused,
        play,
        pause,
    };

    return (
        <AudioPlayerContext.Provider value={contextValue}>{children}</AudioPlayerContext.Provider>
    );
};

export const useAudioPlayerContext = () => {
    const context = useContext(AudioPlayerContext);
    if (context === undefined) {
        throw new Error('useAudioPlayerContext must be used within a AudioPlayerContextProvider');
    }
    return context;
};
