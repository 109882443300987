import { useState } from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import Text from '@/components/ui/atoms/Text';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { PRICING } from '@/utils/pricing';
import { USER_OPTIONS } from '@/utils/userOptions';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import EpisodeAIUnsubscribeModal from '../EpisodeAIUnsubscribeModal';
import EpisodeAISubscriptionModal from '../EpisodeAISubscriptionModal';

const EpisodeAIPricingAction = () => {
    const user = useUserQuery();
    const subscription = useSubscriptionQuery();
    const isFreemium = subscription.data?.isFreemium;
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;
    const hasActiveAddOn = user.data?.options.includes(USER_OPTIONS.EPISODE_AI);
    const hasCancelledAddOn =
        hasActiveAddOn &&
        !subscription.data?.extraItems?.some((item) => item.product === 'episode_ai');
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);

    if (isFreemium) {
        return (
            <Alert data-testid="pricing-ai-freemium">
                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="En complément de votre plan actuel Launch, Boost ou Supersonic." />
                </Text>
            </Alert>
        );
    }

    if (isPro) {
        if (hasActiveAddOn) {
            return (
                <Alert data-testid="pricing-ai-freemium">
                    <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Ausha Intelligence est disponible sur tous les abonnements. Pour annuler, veuillez nous contacter." />
                    </Text>
                </Alert>
            );
        }
        return (
            <ProButton
                href="mailto:sales@ausha.co"
                variant="button-magic"
                startIcon={<EnvelopeIcon icon={icon({ name: 'envelope', style: 'solid' })} />}
                data-testid="pricing-ai-pro-button"
            >
                <FormattedMessage defaultMessage="Contactez nous" />
            </ProButton>
        );
    }

    if (hasCancelledAddOn) {
        return (
            <Alert data-testid="pricing-ai-cancelled">
                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="L'add-on AI a bien été annulé. Vous continuerez à en bénéficier jusqu'à la fin de la période payée." />
                </Text>
            </Alert>
        );
    }

    if (hasActiveAddOn) {
        return (
            <>
                <CancelSubscriptionButton
                    variant="ghost"
                    data-testid="pricing-ai-cancel-subscription-button"
                    onPress={() => setIsUnsubscribeModalOpen(true)}
                >
                    <FormattedMessage defaultMessage="Supprimer l'add-on" />
                </CancelSubscriptionButton>
                <EpisodeAIUnsubscribeModal
                    isOpen={isUnsubscribeModalOpen}
                    onOpenChange={setIsUnsubscribeModalOpen}
                />
            </>
        );
    }

    return (
        <>
            <SubscribeButton
                variant="magic"
                startIcon={<ButtonIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                onPress={() => setIsSubscriptionModalOpen(true)}
                data-testid="pricing-ai-subscribe-button"
            >
                <FormattedMessage defaultMessage="Ajouter AI à mon abonnement" />
            </SubscribeButton>
            <EpisodeAISubscriptionModal
                isOpen={isSubscriptionModalOpen}
                onOpenChange={setIsSubscriptionModalOpen}
            />
        </>
    );
};

const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const SubscribeButton = styled(Button)`
    align-self: stretch;
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: flex-start;
    }
`;
const Alert = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--info500);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const EnvelopeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const ProButton = styled(ExternalLink)`
    align-self: stretch;
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: flex-start;
    }
`;
const CancelSubscriptionButton = styled(Button)`
    color: var(--alert500);
    align-self: start;
    padding: 0;
`;

export default EpisodeAIPricingAction;
